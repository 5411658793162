import {action, makeObservable, observable} from "mobx";
import {BaseStore} from "./baseStore";
import {IStores} from "../types/IStores";
import RecipeActions from "../connector/recipeActions";

class FoodStore extends BaseStore {

    foods = observable.array([], {deep: false});

    constructor(stores: IStores) {
        super(stores);
        makeObservable(this);
    }

    @action
    loadAll() {
        RecipeActions.getAllFood().then((response: any) => {
            const foods = JSON.parse(response.data);
            this.foods.replace(foods);
        })
    }
}

export default FoodStore;