import MeasurementForm from "./measurementForm";
import {UiStore} from "../../stores/uiStore";
import {IMeasurement} from "../../types/IMeasurement";
import {inject} from "mobx-react";
import {MeasurementUtils} from "./measurementsUtil";
import MeasurementActions from "../../connector/measurementActions";
import ProtectedComponent from "../../components/Base/protected";

@inject("measurementStore", "uiStore", "userStore", "recipeStore")
export class MeasurementsSubmit extends ProtectedComponent {

    private async onSave(data: any, currentMeasurement: IMeasurement) {
        currentMeasurement.weight = parseFloat(data.weight);
        currentMeasurement.belly = parseFloat(data.belly);
        currentMeasurement.buttocks = parseFloat(data.buttocks);
        currentMeasurement.powerExercises = parseInt(data.powerExercises);
        currentMeasurement.lightExercises = parseInt(data.lightExercises);
        currentMeasurement.steps = MeasurementUtils.parseIntNaNCheck(data.steps);
        currentMeasurement.menstrualDay = MeasurementUtils.parseIntNaNCheck(data.menstrualDay);
        currentMeasurement.foodLevel = MeasurementUtils.parseIntNaNCheck(data.foodLevel);
        currentMeasurement.needComment = Boolean(data.needComment);
        currentMeasurement.info = data.info ? data.info: null;

        const result = await MeasurementActions.postMeasurement(currentMeasurement);
        const uiStore: UiStore = this.props.uiStore;
        const diff = result.data.diff;
        if (diff !== undefined) {
            uiStore.showDialog(
                `Следующие замеры можно отправить через ${diff} ч.`,
                "Замеры не приняты.", () => {
                    this.props.history.push("/measurement")
                },
                "popup--style popup--red");
        } else {
            uiStore.showDialog(
                "Следующие замеры вы можете отправить не ранее, чем через 24 часа.",
                "Ваши замеры приняты",
                () => {
                    this.props.history.push("/measurement");
                }, "popup--style popup--blue");
        }
    }

    render() {
        return <MeasurementForm  onSave={this.onSave.bind(this)} />;
    }

}