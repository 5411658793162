import React from "react";
import ProtectedComponent from "../../components/Base/protected";
import {images} from "../../components/Utils/componentUtils";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import "./Photo.css";
import {ProgressPhotoComponent} from "./progressPhoto";
import {UiStore} from "../../stores/uiStore";
import {ProgressStore} from "../../stores/progressStore";
import {IProgressPhotosSets} from "../../types/IProgressPhoto";
import moment from "moment";

@inject("uiStore", "userStore", "recipeStore", "progressStore")
@observer
class PhotoAdd extends ProtectedComponent {

    async componentDidMount(): Promise<void> {
        const uiStore: UiStore = this.props.uiStore;
        const progressStore: ProgressStore = this.props.progressStore;
        await progressStore.loadPhotos()
        uiStore.loadingOff();
    }

    render() {
        const uiStore: UiStore = this.props.uiStore;
        if (uiStore.loading) {
            return (<div></div>);
        }
        const progressStore: ProgressStore = this.props.progressStore;
        return (
            <div className="container">
                <h1>Фотографии <span>до/после</span> <img width="32" src={images["camera.png"].default} alt="camera"/></h1>
                <p className="photoadd__p">Сегодня у вас ответственное задание!</p>
                <p className="photoadd__p">Вам нужно сделать фотографии «ДО», именно они станут вашей точкой отсчёта. Это должны быть <b>4 фотографии: вид спереди, вид сзади, вид с правого бока, вид с левого бока.</b>
                 &nbsp;По желанию, вы можете закрыть лицо белым листом бумаги.</p>
                <p><Link to="/">Посмотреть рекомендации для фото <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.02953 6.47064C7.48346 5.9248 6.74297 5.61816 5.97087 5.61816C5.19877 5.61816 4.45827 5.9248 3.9122 6.47064L1.85287 8.52931C1.30678 9.07539 1 9.81603 1 10.5883C1 11.3606 1.30678 12.1012 1.85287 12.6473C2.39895 13.1934 3.13959 13.5002 3.91187 13.5002C4.68414 13.5002 5.42478 13.1934 5.97087 12.6473L7.0002 11.618" stroke="#F55555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.9707 8.52953C6.51677 9.07538 7.25727 9.38201 8.02937 9.38201C8.80147 9.38201 9.54196 9.07538 10.088 8.52953L12.1474 6.47087C12.6935 5.92478 13.0002 5.18414 13.0002 4.41187C13.0002 3.63959 12.6935 2.89895 12.1474 2.35287C11.6013 1.80678 10.8606 1.5 10.0884 1.5C9.31609 1.5 8.57545 1.80678 8.02937 2.35287L7.00004 3.3822" stroke="#F55555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg></Link></p>

                <div className="photoadd__row text-center">
                    {progressStore.lastPhotoSet ?
                        <div className="photoblock__title">{"Фото от " + moment(progressStore.lastPhotoSet.date).format("DD.MM.YYYY")}</div> :
                        <div className="photoblock__title">{"Фото от " + moment().format("DD.MM.YYYY")}</div>
                    }
                    <ProgressPhotoComponent title="ВИД СПЕРЕДИ" type="front" />
                    <ProgressPhotoComponent title="ВИД СЗАДИ" type="back" />
                    <ProgressPhotoComponent title="ВИД С ПРАВОГО БОКА" type="right" />
                    <ProgressPhotoComponent title="ВИД С ЛЕВОГО БЛОКА" type="left" />
                </div>
                <div className="info text-center">Фото до/после не являются обязательными и никак не влияют на гарантию в проекте. Без вашего личного разрешения ваши фотографии мы нигде публиковать не будем. Сделайте их для себя!</div>
                <div className="note">
                    Самые лучшие фото ДО/ ПОСЛЕ будут участвовать в розыгрыше призов.
                    <img className="note__img" width="120" src={images["sticker3.png"].default} alt="sticker"/>
                </div>
                <div className="text-center"><Link className="btn" to="/profile">вернуться в профиль</Link></div>
            </div>
        )
    }
}

export default PhotoAdd;