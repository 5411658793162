import React from "react";
import ProtectedComponent from "../../components/Base/protected";
import {ComponentProgress, images} from "../../components/Utils/componentUtils";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import "./Photo.css";
import ModalImage from "react-modal-image";
import {ProgressStore} from "../../stores/progressStore";
import {ILoading} from "../../types/ILoading";
import {UiStore} from "../../stores/uiStore";
import {IProgressPhotosSets} from "../../types/IProgressPhoto";
import moment from "moment";

@inject("uiStore", "userStore", "recipeStore", "progressStore")
@observer
class Photo extends ProtectedComponent {

    async componentDidMount(): Promise<void> {
        const uiStore: UiStore = this.props.uiStore;
        const progressStore: ProgressStore = this.props.progressStore;
        await progressStore.loadPhotos()
        uiStore.loadingOff();
    }

    render() {
        const uiStore: UiStore = this.props.uiStore;
        if (uiStore.loading) {
            return (<div></div>);
        }
        const progressStore: ProgressStore = this.props.progressStore;
        const photoSets: IProgressPhotosSets = progressStore.photoSets;

        return (
            <div className="container">
                <h1>Фотографии <span>до/после</span> <img width="32" src={images["camera.png"].default} alt="camera"/></h1>
                { photoSets && photoSets.arr.length === 0 &&
                    <div className="notfound notfound--inbasket">
                        <div className="notfound__title">Нет загруженных фотографий</div>
                        <p>Добавить фото до/после можно после нажатия на активную кнопку “Добавить фото”</p><br />
                        <img width="270" src={images["photohow.png"].default} alt="Нет загруженных фотографий"/>
                    </div>
                }

                {photoSets && photoSets.arr.map(photoSet => {
                    return(
                        <div key={photoSet.date} className="photoblock">
                            <div className="photoblock__title">{"Фото от " + moment(photoSet.date).format("DD.MM.YYYY")}</div>
                            <div className="photoblock__images">
                                {photoSet.arr.map(photo => {
                                    return (<ModalImage key={photo.id}
                                        small={progressStore.getPhotoURL(photo)}
                                        large={progressStore.getPhotoURL(photo)}
                                        alt={"Фото от " + photo.date}
                                    />);
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default Photo;