import { Component } from "react";
import { inject, observer } from "mobx-react";
import ProtectedRoute from "./protectedRouter";

@inject("userStore", "uiStore")
@observer
class App extends Component<any, any> {

    render() {
        const {roles} = this.props.userStore;
        return (
            <ProtectedRoute roles={roles}/>
        )
    }

}

export default App;