import React, {Component, createRef} from "react";
import {inject, observer} from "mobx-react";
import {MeasurementStore} from "../../stores/measurementStore";
import {IMeasurement} from "../../types/IMeasurement";
import {UiStore} from "../../stores/uiStore";
import MeasurementForm from "./measurementForm";
import {MeasurementUtils} from "./measurementsUtil";
import MeasurementActions from "../../connector/measurementActions";

const floatRegexp: RegExp = /[\d.]{0,7}/;
const intRegexp: RegExp = /\d{0,2}/;
const intRegexpBig: RegExp = /\d{0,7}/;

const validationsRegexp: {[key: string]: RegExp} = {
    weight: floatRegexp,
    belly: floatRegexp,
    buttocks: floatRegexp,
    powerExercises: intRegexp,
    lightExercises: intRegexp,
    steps: intRegexpBig,
    menstrualDay: intRegexp
};

@inject("uiStore")
@inject("measurementStore")
@observer
class MeasurementEdit extends Component<any, any> {

    private formRef = createRef<any>();

    componentDidMount() {
        const measurementStore: MeasurementStore = this.props.measurementStore;
        const measurementId: number = Number.parseInt(this.props.match.params.id);
        measurementStore.loadMeasurement(measurementId, () => this.formRef.current.setMeasurement(measurementStore.currentMeasurement));
    }

    protected onSave(data: any, currentMeasurement: IMeasurement): void {
        currentMeasurement.id = parseInt(this.props.match.params.id, 10);
        currentMeasurement.weight = parseFloat(data.weight);
        currentMeasurement.belly = parseFloat(data.belly);
        currentMeasurement.buttocks = parseFloat(data.buttocks);
        currentMeasurement.powerExercises = parseInt(data.powerExercises);
        currentMeasurement.lightExercises = parseInt(data.lightExercises);
        currentMeasurement.steps = MeasurementUtils.parseIntNaNCheck(data.steps);
        currentMeasurement.menstrualDay = MeasurementUtils.parseIntNaNCheck(data.menstrualDay);
        currentMeasurement.foodLevel = MeasurementUtils.parseIntNaNCheck(data.foodLevel);
        currentMeasurement.needComment = Boolean(data.needComment);
        currentMeasurement.info = data.info ? data.info: null;

        try {
            MeasurementActions.postMeasurement(currentMeasurement).then((result: any) => {
                const uiStore: UiStore = this.props.uiStore;
                const diffDays = result.data.diffDays;
                if (diffDays) {
                    const days = (1 - diffDays);
                    const hours = Math.ceil(days * 24);
                    const mins = Math.ceil((days * 24 * 60) % 60);
                    uiStore.showDialog(
                        "Эти замеры были созданы слишком давно",
                        "Замеры нельзя редактировать",
                        () => {
                            this.props.history.push("/measurement");
                        });
                } else {
                    uiStore.showDialog(
                        "Ваши замеры были успешно отредактированы. Закройте окно, чтобы вернуться к таблице замеров",
                        "Замеры отредактированы",
                        () => {
                            this.props.history.push("/measurement");
                        });
                }
                console.log(result);
            });
        } catch (err) {
            console.log(err.toString());
        }
    }

    render() {
        return (<MeasurementForm ref={this.formRef} onSave={this.onSave.bind(this)} />);
    }
}

export default MeasurementEdit;