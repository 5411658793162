import {images} from "../../components/Utils/componentUtils";
import {inject, observer} from "mobx-react";
import {
    GOOGLE_ARCHIVE_LINK,
    IntensiveTrains,
    LightTrains,
    Role,
    StepExercises,
    YANDEX_ARCHIVE_LINK,
    YoutubeExercises,
    CircularExercises,
    PilatesExercises,
    TabataExercises,
    SmartFitnessExercises
} from "../../data";
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import {UiStore} from "../../stores/uiStore";
import UserStore from "../../stores/userStore";
import {hasAccessRole, roleErrorPopup} from "../../utils/utils";
import ProtectedComponent from "../../components/Base/protected";
import { TrainSlider } from './components/TrainSlider';

@inject("uiStore", "userStore", "recipeStore")
@observer
class Exercises extends ProtectedComponent {

    render() {
        const uiStore: UiStore = this.props.uiStore;
        const userStore: UserStore = this.props.userStore;
        return (
            <div className="container">
                <div className="title">Программа интенсивных тренировок</div>
                <TrainSlider exercises={IntensiveTrains} />

                {IntensiveTrains.map((train) => (
                   <ModalVideo key={train.id} channel='youtube' autoplay
                               isOpen={uiStore.trainModal[train.id]}
                               videoId={train.href}
                               onClose={() => {uiStore.trainModal[train.id] = false}}
                   />
                ))} 
                <br />  
                <div className="title">Программа легких тренировок</div>

                <TrainSlider exercises={LightTrains} />

                {LightTrains.map((train) => (
                   <ModalVideo key={train.id} channel='youtube' autoplay
                               isOpen={uiStore.trainModal[train.id]}
                               videoId={train.href}
                               onClose={() => {uiStore.trainModal[train.id] = false}}
                   />
                ))}
                <br/>

                <div className="title">Ссылки на просмотр и скачивание тренировок из прямых эфиров</div>
                { hasAccessRole(userStore, Role.EXCERCISES)
                    ? <div className="row--sm">
                        <div className="col-6--sm"><a className="train__downloadlink"
                                                      href={GOOGLE_ARCHIVE_LINK}
                                                      target="_blank" rel="noreferrer"><span className="train__downloadlinkimg"><img
                            width="18" src={images["download3.png"].default} alt="download"/></span>Скачать с Google Диск</a></div>
                        <div className="col-6--sm"><a className="train__downloadlink" href={YANDEX_ARCHIVE_LINK} target="_blank"
                                                      rel="noreferrer"><span className="train__downloadlinkimg"><img
                            width="18" src={images["download4.png"].default} alt="download"/></span>Скачать с Яндекс.Диск</a></div>
                    </div>
                    : <div className="row--sm">
                        <div className="col-6--sm"><a className="train__downloadlink" onClick={() => roleErrorPopup(this.props.uiStore)}><span className="train__downloadlinkimg"><img
                            width="18" src={images["download3.png"].default} alt="download"/></span>Скачать с Google Диск</a></div>
                        <div className="col-6--sm"><a className="train__downloadlink" onClick={() => roleErrorPopup(this.props.uiStore)}><span className="train__downloadlinkimg"><img
                            width="18" src={images["download4.png"].default} alt="download"/></span>Скачать с Яндекс.Диск</a></div>
                    </div>
                }
                <br />

                <div className="title">Зарядки и растяжки</div>

                <TrainSlider isShuffled exercises={YoutubeExercises} />

                {YoutubeExercises.map((train) => (
                <ModalVideo key={train.id} channel='youtube' autoplay
                            isOpen={uiStore.trainModal[train.id]}
                            videoId={train.href}
                            onClose={() => {uiStore.trainModal[train.id] = false}}
                />
                ))}   
                <br /> 
                <div className="title">Шагаем дома</div>

                <TrainSlider isShuffled exercises={StepExercises} />

                {StepExercises.map((train) => (
                <ModalVideo key={train.id} channel='youtube' autoplay
                            isOpen={uiStore.trainModal[train.id]}
                            videoId={train.href}
                            onClose={() => {uiStore.trainModal[train.id] = false}}
                />
                ))}   
                <br /> 
                <div className="title">Тренировки на все тело</div>

                <TrainSlider isShuffled exercises={CircularExercises} />

                {CircularExercises.map((train) => (
                <ModalVideo key={train.id} channel='youtube' autoplay
                            isOpen={uiStore.trainModal[train.id]}
                            videoId={train.href}
                            onClose={() => {uiStore.trainModal[train.id] = false}}
                />
                ))}

                <br />
                <div className="title">Пилатес</div>

                <TrainSlider isShuffled exercises={PilatesExercises} />

                {PilatesExercises.map((train) => (
                    <ModalVideo key={train.id} channel='youtube' autoplay
                                isOpen={uiStore.trainModal[train.id]}
                                videoId={train.href}
                                onClose={() => {uiStore.trainModal[train.id] = false}}
                    />
                ))}

                <br />
                <div className="title">Табаты</div>

                <TrainSlider isShuffled exercises={TabataExercises} />

                {TabataExercises.map((train) => (
                    <ModalVideo key={train.id} channel='youtube' autoplay
                                isOpen={uiStore.trainModal[train.id]}
                                videoId={train.href}
                                onClose={() => {uiStore.trainModal[train.id] = false}}
                    />
                ))}

                <br />
                <div className="title">Умный фитнес</div>

                <TrainSlider isShuffled exercises={SmartFitnessExercises} />

                {SmartFitnessExercises.map((train) => (
                    <ModalVideo key={train.id} channel='youtube' autoplay
                                isOpen={uiStore.trainModal[train.id]}
                                videoId={train.href}
                                onClose={() => {uiStore.trainModal[train.id] = false}}
                    />
                ))}

            </div>
        )
    }
}
export default Exercises;