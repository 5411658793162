import { PasswordForm } from './passwordForm';
import { inject, observer } from 'mobx-react';
import PasswordActions from '../../connector/passwordActions';
import { ComponentProgress } from 'components/Utils/componentUtils';
import BaseComponent from 'components/Base/base';

enum RecoverPhase {
  phone,
  code,
  password,
}

@inject('uiStore')
@observer
class ResetPassword extends BaseComponent {
  params = new URLSearchParams(this.props.history.location.search);

  constructor(props) {
    super(props);
    this.state = {
      progress: false,
      email: '',
      password: '',
      confirmation: '',
      token: this.params.get('token'),
    };
  }

  handleEmail(event: { target: { value: string } }) {
    this.setState({ email: event.target.value });
  }

  handleChangeText({ target }, field) {
    this.setState({ [field]: target.value });
  }

  async handleSubmitEmail() {
    this.setState({ progress: true });

    try {
      await PasswordActions.passwordRecoveryCheck(
        this.state.email,
        null,
        async (response) => {
          const { data, status } = response;

          if (status === 200) {
            this.props.uiStore.showDialog(
              `На почту ${this.state.email} отправлено письмо с дальнейшей инструкцией по восстановлению пароля.`,
              'Поздравляем!',
              () => {
                this.props.history.push('/login');
              },
              'popup--style popup--blue'
            );
          }
        }
      );
      this.setState({ progress: false });
    } catch (error) {}
  }

  async changePassword(e) {
    this.setState({ progress: true });

    PasswordActions.resetPasswordConfirm({
      token: this.state.token,
      password: this.state.password,
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.uiStore.showDialog(
            'Используйте новый пароль для входа в личный кабинет',
            'Пароль успешно сохранен',
            () => this.props.history.push('/login'),
            'popup--style popup--red'
          );
        } else {
          this.props.uiStore.showDialog(
            'Истекло время ожидания, попробуйте снова',
            'Ошибка',
            () => this.setState({ phase: RecoverPhase.phone }),
            'popup--style popup--red'
          );
        }
        this.setState({ progress: false });
      })
      .catch((reason) => {
        this.props.uiStore.showDialog(
          'Попробуйте позже',
          'Произошла ошибка на сервере',
          '',
          'popup--style popup--red'
        );
        this.setState({ progress: false });
      });
  }

  render() {
    return (
      <div className="text-center userform__wrapper">
        <div className="userform">
          <h1 className="title">Восстановление пароля</h1>
          <div className="profil">
            {!this.state.token ? (
              <div id="check-phone">
                <div>Введите e-mail, привязанный к вашей учетной записи</div>
                <br />
                <input
                  name="email"
                  type="text"
                  value={this.state.email}
                  onChange={this.handleEmail.bind(this)}
                  placeholder="Email"
                />
                {!this.state.progress ? (
                  <input
                    className="btn"
                    type="submit"
                    name="submit"
                    value="Подтвердить"
                    onClick={this.handleSubmitEmail.bind(this)}
                  />
                ) : (
                  <ComponentProgress />
                )}
              </div>
            ) : null}
            {this.state.token ? (
              <div id="change-password">
                <div>Придумайте новый пароль (минимум 6 символов)</div>
                <br />
                <PasswordForm
                  state={this.state}
                  handleTextChange={(e, field) =>
                    this.handleChangeText(e, field)
                  }
                  confirm={(e) => {
                    this.changePassword(e);
                  }}
                />
              </div>
            ) : null}
            <div
              className="userform__link"
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <a onClick={() => this.props.history.push('/login')}>
                Вернуться на страницу входа
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
