import { Component } from 'react';
import { images } from '../../../../components/Utils/componentUtils';
import { PlanConstants } from '../../constants';
import { inject, observer } from 'mobx-react';
import { PlanStore } from '../../../../stores/planStore';
import { ISelectedPlanProduct } from '../../../../types/ISelectedPlanProduct';
import { ISelectedPlanProducts } from '../../../../types/ISelectedPlanProducts';
import s from '../../Plan.module.scss';

@inject('planStore')
@inject('uiStore')
@observer
export class PlanProduct extends Component<any, any> {
  private getImageClassName(): string {
    let result: string = s.product__img;
    if (this.props.productVO[2] === 'Желтый') {
      result = `${result} ${s.product__img_orange}`;
    }
    return result;
  }

  private selectedProduct: ISelectedPlanProduct;
  private colorClass: string;

  render() {
    const productVO: any[] = this.props.productVO;
    const planStore: PlanStore = this.props.planStore;
    const foodCategoryId: number = this.props.foodCategoryId;
    const productId: string = planStore.getProductId(productVO);

    let isIncluded: boolean = false;
    const selectedProducts: ISelectedPlanProducts =
      planStore.allSelectedProducts[foodCategoryId];
    const products = selectedProducts.products;
    if (products.has(productId)) {
      this.selectedProduct = products.get(productId);
      isIncluded = true;
    } else {
      this.selectedProduct = {
        id: productId,
        product: productVO,
        grams: planStore.adjustGramsToUserCalorage(
          productVO[PlanConstants.prop.grams]
        ),
        step: PlanConstants.baseStep,
        isUnitStep: productVO.length > PlanConstants.prop.isOne,
        isLeastOne: false,
      };
      this.selectedProduct.grams = planStore.cutGramsToStep(
        this.selectedProduct
      );
    }
    this.colorClass = s.product__quantity;

    if (selectedProducts.hasSelection) {
      if (isIncluded) {
        this.colorClass = `${this.colorClass} ${s.chosen}`;
      } else {
        if (selectedProducts.isOverWeight) {
          this.colorClass = `${this.colorClass} ${s.red}`;
          this.selectedProduct.grams = planStore.getGrammsLeft(
            foodCategoryId,
            this.selectedProduct
          );
        } else {
          if (productVO.length > PlanConstants.prop.isOne) {
            this.selectedProduct.step = productVO[PlanConstants.prop.isOne];
          }
          this.colorClass = `${this.colorClass} ${s.green}`;
          this.selectedProduct.grams = planStore.getGrammsLeft(
            foodCategoryId,
            this.selectedProduct
          );
        }
      }
    } else {
      // do nothing
    }

    // this.selectedProduct.grams = planStore.cutGramsToStep(this.selectedProduct);
    let value: number;
    if (productVO.length > PlanConstants.prop.isOne) {
      this.selectedProduct.step = productVO[PlanConstants.prop.isOne];
      value = Math.floor(
        this.selectedProduct.grams / this.selectedProduct.step
      );
      if (
        this.selectedProduct.grams > 0 &&
        !planStore.allSelectedProducts[foodCategoryId].hasSelection
      ) {
        const unitRemainRatio: number =
          (this.selectedProduct.grams % this.selectedProduct.step) /
          this.selectedProduct.step;
        if (unitRemainRatio >= 0.7) {
          value += 1;
          this.selectedProduct.grams = value * this.selectedProduct.step;
        }
        planStore.baseGrams[planStore.categoryIndex][this.selectedProduct.id] =
          this.selectedProduct.grams;
        if (
          !planStore.unitBaseValues?.[planStore.categoryIndex]?.[
            this.selectedProduct.id
          ]
        ) {
          planStore.unitBaseValues[planStore.categoryIndex] = {
            ...planStore.unitBaseValues[planStore.categoryIndex],
            [this.selectedProduct.id]: value,
          };
        }
        if (value === 1) {
          this.selectedProduct.isLeastOne = true;
        }
      }
    } else {
      value = this.selectedProduct.grams;
    }
    const product_desc = productVO[PlanConstants.prop.description] ? (
      <ul className="product__ul">
        {productVO[PlanConstants.prop.description]
          .split(', ')
          .map((element, index) => (
            <li key={index}>{element}</li>
          ))}
      </ul>
    ) : (
      ''
    );
    return (
      <div className={s.product}>
        <div className={this.getImageClassName()}>
          <img
            src={images[productVO[PlanConstants.prop.image]].default}
            alt="product"
          />
        </div>
        <div className={s.product__description}>
          <div className={s.product__name}>
            {productVO[PlanConstants.prop.name]}
          </div>
          <a
            onClick={() => {
              this.props.uiStore.showDialog(
                product_desc,
                productVO[PlanConstants.prop.name],
                '',
                'popup--product'
              );
            }}
            className={s.product__link}
          >
            Продукты
          </a>
        </div>
        <div className={s.product__counter}>
          <div
            className={s.product__down}
            onClick={() => {
              if (value === 0) {
                return;
              }
              if (isIncluded) {
                this.selectedProduct.grams -= this.selectedProduct.step;
              }
              planStore.modifyProduct(foodCategoryId, this.selectedProduct);
            }}
          />
          <div className={s.product__unit}>
            <input
              type="text"
              className={this.colorClass}
              name="product__quantity"
              value={value}
              onFocus={(event) => {
                event.target.setSelectionRange(0, event.target.value.length);
                event.preventDefault();
              }}
              onChange={(event: any) => {
                if (value === 0) {
                  return;
                }
                const notEmptyProduct: boolean =
                  planStore.allSelectedProducts[foodCategoryId].products.has(
                    productId
                  );
                const inputString: string = notEmptyProduct
                  ? event.target.value
                  : event.nativeEvent.data;
                let newValue: number = Number.parseInt(inputString);
                if (isNaN(newValue)) {
                  this.selectedProduct.grams = 0;
                } else {
                  if (this.selectedProduct.isUnitStep) {
                    newValue =
                      newValue === value
                        ? planStore.baseGrams[planStore.categoryIndex][
                            this.selectedProduct.id
                          ]
                        : newValue * this.selectedProduct.step;
                  }
                  this.selectedProduct.grams = newValue;
                }
                planStore.modifyProduct(
                  foodCategoryId,
                  this.selectedProduct,
                  false
                );
                event.preventDefault();
              }}
            />
          </div>
          <div
            className={s.product__up}
            onClick={() => {
              if (value === 0) {
                return;
              }
              if (isIncluded) {
                this.selectedProduct.grams += this.selectedProduct.step;
              }
              planStore.modifyProduct(foodCategoryId, this.selectedProduct);
            }}
          />
        </div>
      </div>
    );
  }
}
