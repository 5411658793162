import { useEffect, useState } from 'react';
import { images, PendingError } from '../../../components/Utils/componentUtils';
import moment from 'moment';
import { Role } from '../../../data';
import { inject, observer } from 'mobx-react';
import UserStore from '../../../stores/userStore';
import { useForm } from 'react-hook-form';
import { RegexpConstants } from '../../../components/Constants/RegexpConstants';
import { UiStore } from '../../../stores/uiStore';
import { hasAccessRole } from '../../../utils/utils';
import { AvatarPost } from './avatarPost';
import RecipeStore from '../../../stores/recipeStore';
import UserActions from '../../../connector/userActions';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';

export interface IProfileInfoInputs {
  lastName?: string;
  firstName?: string;
  patronymic?: string;
  dob?: string;
  currentWeight?: number;
  targetWeight?: number;
  height?: number;
  caloriesPlan?: number;
  mealPlan?: number;
  country?: string;
  city?: string;
  phone?: string;
}

const ProfileInfo = inject(
  'userStore',
  'uiStore',
  'recipeStore'
)(
  observer((props) => {
    const [requestError, setRequestError] = useState(null);

    const userStore: UserStore = props.userStore;

    const uiStore: UiStore = props.uiStore;
    const recipeStore: RecipeStore = props.recipeStore;
    const user = userStore.profile;

    const onErrorClose = () => {
      setRequestError(null);
      window.location.pathname = '/';
    };

    const inputFile = (e) => {
      let avatar: HTMLElement = document.querySelector(
        'input[name="avatar"]'
      ) as HTMLElement;
      avatar.click();
    };

    const changeFile = (e) => {
      document.querySelector('.choose').innerHTML = e.target.value;
    };

    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<IProfileInfoInputs>({});

    const readValuesFromStore = () => {
      setValue('lastName', userStore.profile?.lastName || '');
      setValue('firstName', userStore.profile?.firstName || '');
      setValue('patronymic', userStore.profile?.patronymic || '');
      setValue(
        'dob',
        userStore.profile?.dob
          ? moment(userStore.profile?.dob).format('DD.MM.YYYY')
          : null
      );
      setValue(
        'currentWeight',
        userStore.profile?.measurements.length
          ? userStore.profile?.measurements[
              userStore.profile?.measurements.length - 1
            ]?.weight
          : ''
      );
      setValue('targetWeight', userStore.profile?.targetWeight || '');
      setValue('height', userStore.profile?.height || '');
      setValue('caloriesPlan', userStore.profile?.caloriesPlan);
      setValue('mealPlan', userStore.profile?.mealPlan);
      setValue('country', userStore.profile?.country || '');
      setValue('city', userStore.profile?.city || '');
    };

    useEffect(() => {
      uiStore.loadingOn();
      userStore.getProfile().then(() => {
        readValuesFromStore();
        uiStore.loadingOff();
      });

      // eslint-disable-next-line
    }, []);

    const onSaveProfileInfo = (data) => {
      const profileInfo: any = {
        first_name: data.firstName,
        last_name: data.lastName,
        patronymic: data.patronymic,
        dob: moment(data.dob, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        // sex: ?
        height: parseInt(data.height),
        country: data.country,
        city: data.city,
        calories_plan: Number(data.caloriesPlan),
        meal_plan: Number(data.mealPlan),
        // maternity: ?
        target_weight: parseInt(data.targetWeight),
      };

      uiStore.loadingOn();
      UserActions.updateProfileInfo(
        profileInfo,
        () => {
          readValuesFromStore();
          uiStore.showDialog(
            'Калораж и план питания можно обновлять не чаще раза в сутки',
            'Профиль не был обновлен.',
            () => {
              window.location.reload();
            },
            'popup--style popup--red'
          );
        },
        () => {
          uiStore.loadingOff();
          uiStore.showDialog(
            'Калораж и план питания можно обновлять не чаще раза в сутки.',
            'Профиль обновлен. ',
            () => {
              uiStore.clearDialog();
            },
            'popup--style popup--blue'
          );
        }
      );
    };

    return user ? (
      <div className="container">
        <h1>
          Личная <span>карта пользователя</span>
          <img width="30" src={images['pencil.png'].default} alt="pencil" />
        </h1>
        <AvatarPost />
        <form
          className="profil"
          action="/"
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSaveProfileInfo)}
        >
          <div className="formquestion">
            <div className="blocktitle">1. Фамилия</div>
            <input
              type="text"
              name="lastName"
              placeholder="Ваша фамилия"
              {...register('lastName', {
                required: 'Требуется указать фамилию',
                maxLength: {
                  value: 32,
                  message: 'Значение не может быть длиннее 32 символов',
                },
              })}
            />
            {errors.lastName && (
              <div className="measure_err">{errors.lastName?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">2. Имя</div>
            <input
              type="text"
              name="firstName"
              placeholder="Ваше имя"
              {...register('firstName', {
                required: 'Требуется указать имя',
                maxLength: {
                  value: 32,
                  message: 'Значение не может быть длиннее 32 символов',
                },
              })}
            />
            {errors.firstName && (
              <div className="measure_err">{errors.firstName?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">3. Отчество</div>
            <input
              type="text"
              name="patronymic"
              placeholder="Ваше отчество"
              {...register('patronymic', {
                maxLength: {
                  value: 32,
                  message: 'Значение не может быть длиннее 32 символов',
                },
              })}
            />
            {errors.patronymic && (
              <div className="measure_err">{errors.patronymic?.message}</div>
            )}
          </div>

          <div className="formquestion">
            <div className="blocktitle">4. Дата рождения: день.месяц.год</div>
            {/* <Controller
              // control={control}
              name="birthDate"
              render={({ field }) => (
                  <DatePicker
                      placeholderText='Select date'
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                  />
              )}
            /> */}
            <input
              type="text"
              name="dob"
              placeholder="Дата рождения: дд.мм.гггг"
              {...register('dob', {
                required: 'Требуется указать дату рождения. Формат дд.мм.гггг',
                pattern: {
                  value: RegexpConstants.dateRegexp,
                  message:
                    'Невалидное значение. Формат дд.мм.гггг Пример "28.12.1986"',
                },
                validate: (value) => {
                  const ts = moment(value, 'DD.MM.YYYY').valueOf();
                  const invalid =
                    ts > moment().subtract(14, 'year').valueOf() ||
                    ts < moment().subtract(99, 'year').valueOf();
                  if (invalid) {
                    return 'Допустимый возраст от 14 до 99 лет';
                  }
                },
              })}
            />
            {errors.dob && (
              <div className="measure_err">{errors.dob?.message}</div>
            )}
          </div>
          <div className="formquestion">
            <div className="blocktitle">5. Текущий вес</div>
            <div className="currentWeight">
              <input
                type="text"
                disabled={true}
                name="currentWeight"
                placeholder="Вес (последние замеры, кг)"
                {...register('currentWeight')}
              />
              <Link to="/measurement/submit" className="btn">
                <span>внести замеры</span>
                <img src={images['diet.png'].default} alt="diet" />
              </Link>
            </div>
          </div>
          <div className="formquestion">
            <div className="blocktitle">6. Желаемый вес</div>
            <input
              type="text"
              name="targetWeight"
              placeholder="Желаемый вес (кг)"
              {...register('targetWeight', {
                required: 'Введите значение от 30 до 300',
                pattern: {
                  value: RegexpConstants.floatRegexp,
                  message: 'Невалидное значение. Пример "59.8"',
                },
                min: {
                  value: 30,
                  message: 'Значение не может быть меньше 30',
                },
                max: {
                  value: 300,
                  message: 'Значение не может быть больше 300',
                },
              })}
            />
            {errors.targetWeight && (
              <div className="measure_err">{errors.targetWeight?.message}</div>
            )}
          </div>
          <div className="formquestion">
            <div className="blocktitle">7. Рост</div>
            <input
              type="text"
              name="height"
              placeholder="Рост (см)"
              {...register('height', {
                required: 'Введите целое значение от 100 до 250',
                pattern: {
                  value: RegexpConstants.intRegexp,
                  message: 'Невалидное значение. Пример "158"',
                },
                min: {
                  value: 100,
                  message: 'Значение не может быть меньше 100',
                },
                max: {
                  value: 250,
                  message: 'Значение не может быть больше 250',
                },
              })}
            />
            {errors.height && (
              <div className="measure_err">{errors.height?.message}</div>
            )}
          </div>
          {/* <select multiple name="diseases">
                    <option value="Заболевания">Заболевания</option>
                     {diseasesList.map((disease, index) => {
                         if(user.profile?.diseases && user.profile?.diseases.includes(index)) {
                            return (
                            <option  key={index} selected value={index}>{disease}</option>
                             );
                         } else {
                              return (
                                <option  key={index} value={index}>{disease}</option>
                              );
                         }
                    }
                    )}
                </select>*/}
          <div className="formquestion">
            <div className="blocktitle">8. Калораж</div>
            <select
              defaultValue={
                userStore.userCaloriesPlan?.id ===
                  userStore.profile?.caloriesPlan &&
                userStore.userCaloriesPlan?.calories
              }
              style={{
                color: !hasAccessRole(userStore, Role.CHANGE_PLAN)
                  ? 'black'
                  : 'gray',
              }}
              // disabled={!hasAccessRole(userStore, Role.CHANGE_PLAN)}
              name="caloriesPlan"
              {...register('caloriesPlan')}
              placeholder={'Выберите калораж'}
            >
              {!userStore.profile?.caloriesPlan && (
                <option value={null}>Выберите калораж</option>
              )}
              {userStore?.caloriesPlan.map((caloriesPlanItem) => {
                return (
                  <option
                    key={caloriesPlanItem?.id}
                    value={caloriesPlanItem?.id}
                  >
                    {caloriesPlanItem?.calories}
                  </option>
                );
              })}
            </select>
            {userStore.userCaloriesPlan?.calories <= 1200 ? (
              <div className="infoblock">
                Мы категорически не рекомендуем использовать план питания менее
                1200 ккал. Это может серьезно навредить вашему здоровью.
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="formquestion">
            <div className="blocktitle">9. План питания</div>
            <select
              defaultValue={
                userStore.userMealPlan?.id === userStore.profile?.mealPlan &&
                userStore.userMealPlan?.title
              }
              // value={userStore.userMealPlan?.id}
              style={{
                color: !hasAccessRole(userStore, Role.CHANGE_GOAL)
                  ? 'black'
                  : 'gray',
              }}
              // disabled={!hasAccessRole(userStore, Role.CHANGE_GOAL)}
              name="mealPlan"
              {...register('mealPlan')}
              placeholder={'План питания'}
            >
              {!userStore?.profile.mealPlan && (
                <option value={null}>Выберите план питания</option>
              )}
              {userStore?.mealsPlan.map((mealPlanItem) => {
                return (
                  <option key={mealPlanItem.id} value={mealPlanItem.id}>
                    {mealPlanItem.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="formquestion">
            <div className="blocktitle">10. Страна</div>
            <input
              type="text"
              name="country"
              placeholder="Страна проживания"
              {...register('country', {
                required: 'Требуется указать страну проживания',
              })}
            />
            {errors.country && (
              <div className="measure_err">{errors.country?.message}</div>
            )}
          </div>
          <div className="formquestion">
            <div className="blocktitle">11. Город</div>
            <input
              type="text"
              name="city"
              placeholder="Город проживания"
              {...register('city', {
                required: 'Требуется указать город проживания',
              })}
            />
            {errors.city && (
              <div className="measure_err">{errors.city?.message}</div>
            )}
          </div>
          <button className="profil__save" type="submit">
            сохранить
            <img width="20" src={images['diet.png'].default} alt="diet" />
          </button>
          <br />
          <div className="text-center">
            <Link
              to={'/login'}
              onClick={() => {
                userStore.logOut();
                props.history.push('/login');
              }}
              className="btn"
            >
              выйти из кабинета <Icon>logout</Icon>
            </Link>
          </div>
        </form>
      </div>
    ) : (
      <PendingError
        error={requestError}
        message={requestError?.message}
        onClose={onErrorClose}
      />
    );
  })
);

export default ProfileInfo;
