import React, { Component, createRef } from 'react';
import { FixedSizeList } from 'react-window';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { getFullPath } from '../../../../utils/utils';
import { MealTime } from '../../../../data';
import RecipeStore from '../../../../stores/recipeStore';
import { IRecipe } from '../../../../types/IRecipe';
import { ISearchItem } from '../../../../types/ISearchItem';
import { hasEntriesMatch, hasPartialMatch } from '../../../../utils/filter';
import { images } from '../../../../components/Utils/componentUtils';
import { SessionStorageUtil } from '../../../../components/Utils/sessionStorageUtil';
import BasketStore from '../../../../stores/basketStore';
import { BasketLabel } from './basketLabel';
import { WishLabel } from './wishLabel';

const scrollRef = createRef<FixedSizeList>();

@inject('basketStore', 'recipeStore')
@observer
export class MenuCategoryList extends Component<any, any> {
  private mounted: boolean;

  componentDidMount() {
    const scrollOffset: number = SessionStorageUtil.getInt(
      SessionStorageUtil.RECIPES_SCROLL_POSITION
    );
    scrollRef.current.scrollTo(scrollOffset);
    this.mounted = true;
  }

  private filterRecipes(
    allRecipes: IRecipe[],
    searchItems: ISearchItem[]
  ): IRecipe[] {
    if (!searchItems || searchItems.length === 0) {
      return allRecipes;
    }
    const result: IRecipe[] = [];
    const entries = searchItems.map((item) => item.key);
    const filterString = entries.join('');
    for (const recipe of allRecipes) {
      if (
        hasEntriesMatch(entries, recipe.components) ||
        hasPartialMatch(filterString, recipe.title)
      ) {
        result.push(recipe);
      }
    }
    return result;
  }

  render() {
    const recipeStore: RecipeStore = this.props.recipeStore;
    const allRecipes =
      recipeStore.categoryRecipes[recipeStore.selectedCategory];
    const recipes: IRecipe[] =
      this.filterRecipes(allRecipes, recipeStore.searchItems) || [];
    const basketStore: BasketStore = this.props.basketStore;
    return (
      <div>
        {recipes.length > 0 && (
          <div className="title__find">Найдено {recipes.length} рецептов</div>
        )}
        {recipes.length === 0 && (
          <div className="notfound notfound--search">
            <img src={images['nofound.svg'].default} alt="Нет рецептов" />
            <div className="notfound__title">
              Нет рецептов, соответствующих условиям поиска
            </div>
            <p>Пожалуйста, измените, условия поиска</p>
          </div>
        )}
        <FixedSizeList
          ref={scrollRef}
          height={500}
          itemSize={270}
          itemCount={recipes.length}
          itemData={recipes}
          onScroll={({ scrollOffset }) => {
            if (this.mounted) {
              SessionStorageUtil.setInt(
                SessionStorageUtil.RECIPES_SCROLL_POSITION,
                scrollOffset
              );
            }
          }}
        >
          {(props) => {
            const recipeIndex = props.index;
            const style = props.style;
            const recipe: IRecipe = recipes[recipeIndex];
            return (
              <div
                style={style}
                className="item"
                key={'categoryRecipe' + recipeIndex}
              >
                <div className="recipe__item">
                  <BasketLabel recipe={recipe} />
                  <WishLabel recipe={recipe} />
                  <Link
                    to={'/recipe/' + recipe.id}
                    className="recipe__image"
                    style={{
                      backgroundImage: `url(${recipe.image})`,
                    }}
                  >
                    {' '}
                  </Link>
                  <Link to={'/recipe/' + recipe.id} className="recipe__text">
                    <span className="recipe__desc">
                      {MealTime[recipe.mealTime]}
                      {recipe.timeToCook ? '|' + recipe.timeToCook : null}
                    </span>
                    <span className="recipe__title">{recipe.title}</span>
                    <span className="recipe__links">
                      {recipe.components.map((food, index) => {
                        return index < 3 ? (
                          <span
                            className={recipeStore.foodClassNames[index % 3]}
                            key={'category' + recipeIndex + food.title}
                          >
                            {food.title}
                          </span>
                        ) : null;
                      })}
                      {!recipe?.expanded && recipe.components.length > 3 ? (
                        <span
                          style={{ cursor: 'pointer' }}
                          className={recipeStore.foodClassNames[0]}
                          key={'daily' + recipeIndex + 'more'}
                        >
                          ...
                        </span>
                      ) : null}
                      {recipe.components.map((food, index) => {
                        return recipe?.expanded && index >= 3 ? (
                          <span
                            className={recipeStore.foodClassNames[index % 3]}
                            key={'daily' + recipeIndex + food.title}
                          >
                            {food.title}
                          </span>
                        ) : null;
                      })}
                    </span>
                  </Link>
                </div>
              </div>
            );
          }}
        </FixedSizeList>
      </div>
    );
  }
}
