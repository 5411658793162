import { connector } from '../index';

export default class RecipeActions {
  static async getFavorite() {
    return connector.get('/foods/recipes/?favorites=true');
  }

  static async addToFavorite(recipeId: number, onError, onSuccess) {
    return connector.post(
      '/foods/recipes/favorite/',
      { recipe: recipeId },
      onError,
      onSuccess
    );
  }

  static async removeFromFavorite(recipeId, onError, onSuccess) {
    return connector.delete(
      `/foods/recipes/favorite/${recipeId}/`,
      {},
      onError,
      onSuccess
    );
  }

  static async getAll(mealTime: number) {
    return connector.get('/foods/recipes/', { meal_time: mealTime });
  }

  static async getRecipe(id: number) {
    return connector.get(`/foods/recipes/${id}/`);
  }

  static async searchRecipes(filter) {
    return connector.post('/recipe/search', filter);
  }

  static async getSimilarRecipes(id) {
    return connector.get('/recipe/similar/' + id);
  }

  static async getAllFood() {
    return connector.get('/food');
  }
}
