import { Component } from 'react';
import { Role } from '../data';
import { BaseDialog } from '../components/Utils/componentUtils';
import { Route, Switch } from 'react-router-dom';
import Plan from '../pages/Plan';
import { routes } from './routes';

class ProtectedRoute extends Component<any, any> {
  handleClose() {
    localStorage.removeItem('auth');
    window.location.pathname = '/login';
  }

  render() {
    const { roles } = this.props;

    return (
      <Switch>
        {routes.map((route, i) => {
          const key = route.path + i;
          //   if (
          //     !route.roles ||
          //     roles?.includes(Role.CURATOR) ||
          //     roles?.includes(Role.ADMIN)
          //   ) {
          //     return (
          //       <Route
          //         key={key}
          //         path={route.path}
          //         exact={route.exact}
          //         component={route.component}
          //       />
          //     );
          //   }
          // const isAuthenticated = !!roles?.length;
          // if (!isAuthenticated) {
          //     return <Route component={Login} key={key}/>
          // }
          const hasPermission = route.roles?.some((role) =>
            roles?.includes(role)
          );

          // ALERT инверсия ждем роли
          if (!hasPermission) {
            return (
              <Route
                key={key}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            );
          }
          const hasToken = !!localStorage.getItem('auth');
          if (hasToken) {
            return <Route component={Plan} key={key} />;
          }

          return (
            <Route key={key} path={route.path} exact={route.exact}>
              <BaseDialog
                message={
                  <div>
                    <p>
                      Нажмите на кнопку "Стать участником", чтобы ознакомится с
                      тарифами и присоединиться к проекту
                    </p>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <a className="btn" href="https://4yourfit.by/kurs/#tarif">
                        Стать участником
                      </a>
                      <a className="btn" onClick={this.handleClose.bind(this)}>
                        {' '}
                        Я уже участвую
                      </a>
                    </div>
                  </div>
                }
                title="Только для участников проекта Татьяны Метельской"
                isOpen={true}
                handleClose={() => this.handleClose.bind(this)}
              />
            </Route>
          );
        })}
      </Switch>
    );
  }
}

export default ProtectedRoute;
