export interface ErrorDescription {
  message: string;
  title?: string;
  shouldReload?: boolean;
}

export const customError = (reason: any): ErrorDescription => {
  const errorsResponse = reason.response?.data;

  for (const key in errorsResponse) {
    if (Object.prototype.hasOwnProperty.call(errorsResponse, key)) {
      return {
        message: errorsResponse[key][0],
        title: `Ошибка поля ${key}!`,
        shouldReload: true,
      };
    }
  }
};
