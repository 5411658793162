import React from "react";
import {Link} from "react-router-dom";
import {images} from "../../components/Utils/componentUtils";
import {inject, observer} from "mobx-react";
import {FixedSizeList} from 'react-window';
import {MeasurementStore} from "../../stores/measurementStore";
import {IMeasurement} from "../../types/IMeasurement";
import {monthsStrings, timeRanges} from "./measurementsConstants";
import {UiStore} from "../../stores/uiStore";
import {MeasurementUtils} from "./measurementsUtil";

import UserStore from "../../stores/userStore";
import {hasAccessRole, roleErrorPopup} from "../../utils/utils";
import {Role} from "../../data";
import moment from "moment";
import ProtectedComponent from "../../components/Base/protected";

@inject("measurementStore", "uiStore", "userStore", "recipeStore")
@observer
class MeasurementTable extends ProtectedComponent {

    private canModifyDiff: number = 96 * 60 * 60 * 1000;

    async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        this.props.measurementStore.loadMeasurements();
    }

    private onTimeRangeChange(e) {
        const measurementStore: MeasurementStore = this.props.measurementStore;
        const range: string = e.target.value;
        if (range !== measurementStore.currentRange) {
            measurementStore.setCurrentRange(range);
        }
    }

    private deleteMeasurement(measurement: IMeasurement) {
        this.props.measurementStore.deleteMeasurement(measurement.id, this.props.history.push("/measurement"));
    }

    render() {
        const measurementStore: MeasurementStore = this.props.measurementStore;
        const uiStore: UiStore = this.props.uiStore;
        const userStore: UserStore = this.props.userStore;
        const clickedMeasurement: IMeasurement = measurementStore.clickedMeasurement;
        let measurements: IMeasurement[] = measurementStore.measurements;
        measurements = measurements.slice().sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());
        const nowDate: Date = new Date(measurementStore.nowDate);
        const nowDateTime: number = nowDate.getTime();
        const isMeasurementDay: boolean = MeasurementUtils.getIsTodayMeasurementEditDay();
        return (
            <div className="container">
                <h1>Таблица <span>замеров</span> <img width="32" src={images["checkup.png"].default} alt="checkup"/>
                </h1>
                <form noValidate>
                    <div className="measure__period">
                        <span className="measure__periodtext">Период</span>
                        <select name="select" onChange={this.onTimeRangeChange.bind(this)}
                                value={measurementStore.currentRange}>
                            <option value={timeRanges.month}>Месяц</option>
                            <option value={timeRanges.year}>Год</option>
                            <option value={""}>За все время</option>/
                            {/*<option value="free">Произвольно</option>*/}
                        </select>
                    </div>
                    {/*<div className="measure__period"><span className="measure__periodtext">Начальная дата</span><TextField defaultValue={ this.state.startDate } value={ this.state.startDateValue } onChange={this.handleChangeDate} type="date" InputLabelProps={{shrink: true,}}/></div> */}
                    {/*<div className="measure__period"><span className="measure__periodtext">Конечная дата</span><TextField type="date" defaultValue={ this.state.currentDate } InputLabelProps={{shrink: true,}}/></div>              */}
                </form>
                <div className="measure__row measure__row--top">
                    <div className="measure__date"></div>
                    <div className="measure__numbers">
                        <div className="measure__weight"><img width="20" src={images["weight.png"].default}
                                                              alt="weight"/></div>
                        <div className="measure__belly"><img width="22" src={images["slim.png"].default} alt="belly"/>
                        </div>
                        <div className="measure__buttocks"><img width="20" src={images["slim3.png"].default}
                                                                alt="buttocks"/></div>
                        <div className="measure__editcol"><img width="18" src={images["gear.png"].default} alt="edit"/>
                        </div>
                    </div>
                </div>
                {(measurements && measurements.length) ?
                    <FixedSizeList height={390} width="100%" itemSize={65}
                                   itemCount={measurements.length}
                                   itemData={measurements}>
                        {props => {
                            const {data, index, style} = props;
                            const measurement: IMeasurement = data[index];
                            const date: Date = new Date(measurement.date);
                            const day: number = date.getDate();
                            const month: number = date.getMonth();

                            const timeDiff: number = nowDateTime - date.getTime();
                            const canModify: boolean = measurement.id && timeDiff < this.canModifyDiff;

                            return (<div style={style} className="measure__row">
                                <div className="measure__date">
                                    <div>{day}</div>
                                    {monthsStrings[month]}</div>
                                <div className="measure__numbers">
                                    <div className="measure__weight">{measurement.weight} кг</div>
                                    <div className="measure__belly">{measurement.belly} см</div>
                                    <div className="measure__buttocks">{measurement.buttocks} см</div>
                                    {canModify &&
                                    <div className="measure__editcol">
                                            <span className="measure__edit"
                                                  onClick={() => {
                                                      if (measurementStore.clickedMeasurement) {
                                                          measurementStore.setClickedMeasurement(null);
                                                      } else {
                                                          measurementStore.setClickedMeasurement(measurement);
                                                      }
                                                  }}
                                            ><span></span><span></span><span></span></span>
                                        {(clickedMeasurement && clickedMeasurement.id === measurement.id) &&
                                        <div className="measure__editpopup">
                                            <div>
                                                <Link to={"/measurement/" + measurement.id}>
                                                    <img width="16" src={images["edit.png"].default} alt="edit"/>
                                                    <span>Редактировать данные</span>
                                                </Link>
                                            </div>
                                            <div>
                                                <div data-method="delete" onClick={() => {
                                                    this.deleteMeasurement(measurement);
                                                }}>
                                                    <img width="16" src={images["delete.png"].default} alt="delete"/>
                                                    <span>Удалить</span>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>);
                        }}
                    </FixedSizeList>
                :
                    <div>Нет замеров за выбранный период. Внесите замеры для отображения.</div>
                }
                <br/>
                {hasAccessRole(userStore, Role.MEASUREMENTS)
                    ? <div className="text-center"><Link className="btn" to="/measurement/submit">внести замеры</Link>
                    </div>
                    : <div className="text-center"><Link to={"/"} className="btn" onClick={() => roleErrorPopup(uiStore)}>внести
                        замеры</Link></div>
                }
                <br/>
                <div className="text-center"><Link className="btn" to="/profile">вернуться в профиль</Link></div>
            </div>);
    }
}

export default MeasurementTable;