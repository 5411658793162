const leven = require("leven");
const levenPrecision = 0.35;

export function hasEntriesMatch(checkedArray, targetArray) {
    let matches = 0;
    for (const entry of checkedArray) {
        if (targetArray.map(food => food.title.trim()).some(foodTitle => hasPartialMatch(entry, foodTitle))) {
            matches++;
        } else {
            break;
        }
    }
    return matches === checkedArray.length;
}

export function hasPartialMatch(desiredValue, targetValue) {
    if (desiredValue.length >= targetValue) {
        return leven(targetValue.toLowerCase(), desiredValue.toLowerCase()) <= targetValue.length * levenPrecision;
    }
    for (let i = 0; i < targetValue.length - desiredValue.length + 1; i++) {
        const substr = targetValue.substr(i, desiredValue.length);
        if (leven(substr.toLowerCase(), desiredValue.toLowerCase()) <= (substr.length * levenPrecision)) {
            return true;
        }
    }
    return false;
}