import {Component} from "react";
import {inject, observer} from "mobx-react";
import {ProgressLoader} from "./componentUtils";

@inject("uiStore")
@observer
export class Loader extends Component<any, any> {

    render() {
        return <ProgressLoader open={this.props.uiStore.loading}/>;
    }
}