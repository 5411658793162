import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'mobx-react';
import UserStore from "./stores/userStore";
import FoodStore from "./stores/foodStore";
import RecipeStore from "./stores/recipeStore";
import BasketStore from "./stores/basketStore";
import Header from "./components/Header/header";
import BottomMenu from "./components/BottomMenu/bottomMenu";
import App from "./App";
import {IStores} from "./types/IStores";
import {PlanStore} from "./stores/planStore";
import {UiStore} from "./stores/uiStore";
import {AlertDialog} from "./components/Utils/alertDialog";
import {MeasurementStore} from "./stores/measurementStore";
import {Loader} from "./components/Utils/loader";
import Connector from "./connector/connector";
import {BrowserRouter} from "react-router-dom";
import {ProgressStore} from "./stores/progressStore";
import {ValidationFormStore} from "./stores/validationFormStore";
import { SupportStore } from "./stores/supportStore";

const stores: IStores = new class implements IStores {
    basketStore: BasketStore = new BasketStore(this);
    foodStore: FoodStore = new FoodStore(this);
    measurementStore: MeasurementStore = new MeasurementStore(this);
    planStore: PlanStore = new PlanStore(this);
    recipeStore: RecipeStore = new RecipeStore(this);
    uiStore: UiStore = new UiStore(this);
    userStore: UserStore = new UserStore(this);
    progressStore: ProgressStore = new ProgressStore(this);
    validationFormStore: ValidationFormStore = new ValidationFormStore(this);
    supportStore: SupportStore = new SupportStore(this);
}();

export const connector = new Connector(stores);
export const StoreContext = React.createContext(null);

ReactDOM.render(
    <Provider {...stores}>
        <BrowserRouter>
            <StoreContext.Provider value={stores}>
                <div>
                    <Header/>
                    <App/>
                    <BottomMenu/>
                    <AlertDialog/>
                    <Loader/>
                </div>
            </StoreContext.Provider>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));