import { useEffect } from 'react';
import SupportLayout from './Layout/SupportLayout';
import { SectionButtonBlock } from './components/SectionButtonBlock';
import { LinkChatBlock } from './components/LinkChatBlock';
import { ButtonType, SupportButton } from './components/SupportButton';
import { Content } from './components/Content';
import { NavigationButtons } from './components/NavigationButtons';
import { useStore } from '../../stores/useStore';
import { observer } from 'mobx-react';
import { UnderContentButtonsBlock } from './components/UnderContentButtons/UnderContentButtonsBlock';
import s from './Support.module.scss';
import { images } from '../../components/Utils/componentUtils';

export const Support = observer(() => {
  const { supportStore } = useStore();
  const { isVisibleMenu, currentContent, currentSection } = supportStore;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentContent]);

  return (
    <SupportLayout>
      {isVisibleMenu ? (
        <>
          <LinkChatBlock />
          <h1 className={s.title}>
            {'База знаний'}
            <span>
              {'по проекту'}
              <img width="30" src={images['exam.png'].default} alt="pencil" />
            </span>
          </h1>
          <SectionButtonBlock />
        </>
      ) : (
        <div>
          {/*Используется как заголовок*/}
          <h1 className={s.title}>
            {'База знаний'}
            <span>
              {'по проекту'}
              <img width="30" src={images['exam.png'].default} alt="pencil" />
            </span>
          </h1>
          <SupportButton
            style={{
              justifyContent: 'center',
              marginBottom: '16px',
            }}
            contentAlign={'center'}
            cursor={'auto'}
            text={currentContent?.buttonProps?.text ?? currentSection.text}
            width={'100%'}
            height={60}
            type={ButtonType.WithIcon}
            leftIcon={currentContent?.buttonProps?.icon ?? currentSection.icon}
            leftIconProps={
              currentContent.isFirstSlide && currentSection.iconProps
            }
          />
          <Content />
          <NavigationButtons />
          <UnderContentButtonsBlock />
        </div>
      )}
    </SupportLayout>
  );
});
