import React, {forwardRef, useImperativeHandle} from "react";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {images} from "../../components/Utils/componentUtils";
import {MeasurementStore} from "../../stores/measurementStore";
import {IMeasurement} from "../../types/IMeasurement";
import {inject} from "mobx-react";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {UiStore} from "../../stores/uiStore";
import {RegexpConstants} from "../../components/Constants/RegexpConstants";

type IMeasurementInputs = {
    weight: number;
    belly: number;
    buttocks: number;
    powerExercises: number;
    lightExercises: number;
    steps: number;
    foodLevel: string;
    menstrualDay: number;
    needComment: boolean;
    info: string;
};

export interface IMeasurementFormProps {
    onInit?: (data: any) => Promise<any>;
    onSave: (data: any, measurement: IMeasurement) => Promise<any>;
    measurementStore?: MeasurementStore;
    uiStore?: UiStore;
}

const MeasurementForm = inject("measurementStore", "uiStore")(forwardRef((props: IMeasurementFormProps, ref) => {

    const measurementStore: MeasurementStore = props.measurementStore;
    const uiStore: UiStore = props.uiStore;
    const currentMeasurement: IMeasurement = measurementStore.currentMeasurement;
    const onSave = props.onSave;
    const history = useHistory();

    const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm<IMeasurementInputs>({
        mode: "onBlur",
    });

    function submit (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const errorsValues = Object.values(errors)
        if (errorsValues.length > 0) {
            e.preventDefault();
            errorsValues[0].ref.focus();
        }
        return;
    }

    useImperativeHandle(ref, () => ({
        setMeasurement: (measurement: IMeasurement) => {
            setValue("weight", measurement.weight);
            setValue("belly", measurement.belly);
            setValue("buttocks", measurement.buttocks);
            setValue("powerExercises", measurement.powerExercises);
            setValue("lightExercises", measurement.lightExercises);
            setValue("steps", measurement.steps);
            setValue("menstrualDay", measurement.menstrualDay);
            setValue("needComment", measurement.needComment);
            setValue("info", measurement.info);
            setValue("foodLevel", measurement.foodLevel ? measurement.foodLevel.toString() : null);
        }
    }));

    const onNeedCommentChange = (data) => {
        currentMeasurement.needComment = !currentMeasurement.needComment;
    }

    const onSaveMesument = (data) => {
        onSave(data, currentMeasurement);
    }

    return (
        <div className="container">
            <h1><span>Замеры</span> участника <img width="36" src={images["diet.png"].default} alt="diet"/></h1>
            <div className="note">
                Добрый день! Напоминаю, что замеры производятся натощак, утром до еды.
                <img className="note__img" src={images["sticker1.png"].default} alt="sticker"/>
            </div>
            <form action="/" encType="multipart/form-data" method="post" onSubmit={handleSubmit(onSaveMesument)}>
                <div className="formquestion">
                    <div className="blocktitle">
                        1. Укажите ваш вес на текущий момент?
                    </div>
                    <input
                        className={errors.weight ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="weight"
                        step="0.001"
                        placeholder="0.00 кг"
                        {...register("weight", {
                            required: "Требуется указать вес в килограммах",
                            pattern: {
                                value: RegexpConstants.floatRegexp,
                                message: "Невалидное значение. Пример \"57.400\""
                            },
                            max: {
                                value: 300,
                                message: "Значение слишком большое"
                            },
                        })}
                    />
                    {errors.weight && <div className="measure_err">{errors.weight?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">
                        2. Объем живота, на уровне пупка
                    </div>
                    <input
                        className={errors.belly ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="belly"
                        step="0.001"
                        placeholder="0.00 см"
                        {...register("belly", {
                            required: "Требуется указать объем в талии",
                            pattern: {
                                value: RegexpConstants.floatRegexp,
                                message: "Невалидное значение. Пример \"74.2\"",
                            },
                            max: {
                                value: 300,
                                message: "Значение слишком большое"
                            },
                        })}
                    />
                    {errors.belly && <div className="measure_err">{errors.belly?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">
                        3. Объем ягодиц, В самой широк части бедер
                    </div>
                    <input
                        className={errors.buttocks ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="buttocks"
                        step="0.001"
                        placeholder="0,00 см"
                        {...register("buttocks", {
                            required: "Требуется указать объем в бедрах",
                            pattern: {
                                value: RegexpConstants.floatRegexp,
                                message: "Невалидное значение. Пример \"112.8\""
                            },
                            max: {
                                value: 300,
                                message: "Значение слишком большое"
                            },
                        })}
                    />
                    {errors.buttocks && <div className="measure_err">{errors.buttocks?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">
                        4. Сколько силовых и интенсивных тренировок у вас было за последние 7 дней?
                    </div>
                    <p>Все тренировки, кроме легких тренировок в записи, растяжек и зарядок</p>
                    <input
                        className={errors.powerExercises ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="powerExercises"
                        placeholder="0"
                        {...register("powerExercises", {
                            required: "Требуется указать количество интенсивных тренировок за последние 7 дней",
                            pattern: {
                                value: RegexpConstants.intRegexp,
                                message: "Невалидное значение. Пример \"5\""
                            }
                        })}
                    />
                    {errors.powerExercises && <div className="measure_err">{errors.powerExercises?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">
                        5. Сколько легких тренировок либо тренировок по растяжке у вас было за последние 7 дней?
                    </div>
                    <input
                        className={errors.lightExercises ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="lightExercises"
                        placeholder="0"
                        {...register("lightExercises", {
                            required: "Требуется указать количество легких тренировок за последние 7 дней",
                            pattern: {
                                value: RegexpConstants.intRegexp,
                                message: "Невалидное значение. Пример \"7\""
                            }
                        })}
                    />
                    {errors.lightExercises && <div className="measure_err">{errors.lightExercises?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">6. Достаточно ли вам еды в вашем плане питания?</div>

                    <Controller
                        rules={{required: "Необходимо указать, достаточно ли вам еды"}}
                        control={control}
                        name={"foodLevel"}
                        render={({ field: {value, ref, onBlur, onChange, name}, formState, fieldState }) =>
                            (<RadioGroup
                                onChange={onChange}
                                onBlur={onBlur}
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label="Достаточно, в самый раз"
                                    checked={value === "1"}
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label="Много еды, не могу столько съесть"
                                    checked={value === "2"}
                                />
                                <FormControlLabel
                                    value="3"
                                    checked={value === "3"}
                                    control={<Radio />}
                                    label="Мало еды, голодаю"
                                />
                            </RadioGroup>
                        )}
                    />
                    {errors.foodLevel && <div className="measure_err">{errors.foodLevel?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">
                        7. Укажите СРЕДНЕЕ количество шагов, которое вы прошли за последние 7 дней.
                    </div>
                    {/*<p>Если у вас в среднем более 12 000 шагов, то нужно прислать куратору скрин из приложения,*/}
                    {/*    учитывающего количество шагов, подтверждающий ваш ответ. <span>Этот пункт заполняем, если хотим участвовать в лидербордах и иметь шанс выиграть дополнительные призы</span>*/}
                    {/*</p>*/}
                    <input
                        className={errors.steps ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="steps"
                        placeholder="0"
                        {...register("steps", {
                            required: false,
                            pattern: {
                                value: RegexpConstants.intRegexpBig,
                                message: "Невалидное значение. Пример \"9430\""
                            }
                        })}
                    />
                    {errors.steps && <div className="measure_err">{errors.steps?.message}</div>}
                </div>
                <div className="formquestion">
                    <div className="blocktitle">
                        8. Если вы девушка, день менструального цикла
                    </div>
                    <input
                        className={errors.menstrualDay ? "inputNumberNotValid" : "inputNumberDefault"}
                        type="number"
                        name="menstrualDay"
                        placeholder="0"
                        {...register("menstrualDay", {
                            required: false,
                            pattern: {
                                value: RegexpConstants.menstrualRegexp,
                                message: "Невалидное значение. Пример \"5\""
                            }
                        })}
                    />
                    {errors.menstrualDay && <div className="measure_err">{errors.menstrualDay?.message}</div>}
                </div>
                {/*<div className="formquestion">*/}
                {/*    <div className="blocktitle">9. Необходим ли вам комментарий куратора по вашим замерам?</div>*/}
                {/*    <p>Только для участников на тарифе «Питание + тренировки»</p>*/}
                {/*    <div className="formlabel">*/}
                {/*        <input type="checkbox" name="needComment" id="comment2"*/}
                {/*               {...register("needComment", {required: false})}*/}
                {/*               onChange={onNeedCommentChange} />*/}
                {/*        <label htmlFor="comment2">Нужен</label>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="formquestion">
                    <div className="blocktitle">
                        9. Прочая информация
                    </div>
                    <textarea name="info" placeholder="Мой комментарий"
                              {...register("info", {required: false, maxLength: 2000})}/>
                    {errors.info && <div className="measure_err">{errors.info?.message}</div>}
                </div>
                <button type="submit" onClick={submit}>
                    отправить
                    <img width="20" src={images["diet.png"].default} alt="diet"/>
                </button>
                <br />
                <div className="text-center">
                    <Link to="/measurement" className="btn">
                        таблица замеров
                        <img width="20" src={images["checkup.png"].default} alt="checkup"/>
                    </Link>
                </div>
            </form>
        </div>
    );
}));

export default MeasurementForm;