import { connector } from '../index';
import { IBasketRecipe } from '../types/IBasketRecipe';

export default class BasketActions {
  static async getBasket() {
    return connector.get('/foods/recipes/?in_basket=true');
  }

  static async resetBasket() {
    return connector.delete('/foods/recipes/basket/empty/', null);
  }

  static async addRecipe(recipe: IBasketRecipe, onError, onSuccess) {
    return connector.post(
      '/foods/recipes/basket/',
      { recipe: recipe.id },
      onError,
      onSuccess
    );
  }

  static async setBasketRecipeCount(recipeId: number, count: number) {
    return connector.post('/basket/recipecount', { recipeId, count });
  }

  static async deleteRecipe(id: number, onError, onSuccess) {
    return connector.delete(`/foods/recipes/basket/${id}/`, onError, onSuccess);
  }

  static async addFood(foodId: number) {
    return connector.post('/basket/food', { food: foodId });
  }

  static async removeFood(foodId: number) {
    return connector.delete('/basket/food', foodId);
  }
}
