import { action, makeObservable, observable, computed } from 'mobx';
import { BaseStore } from './baseStore';
import { IStores } from '../types/IStores';
import { images } from '../components/Utils/componentUtils';
import { IProfile } from '../types/IProfile';
import AuthActions from '../connector/authActions';
import UserActions from '../connector/userActions';
import { CHATS_3, subscriptionsChats } from '../data';
import { AxiosResponse } from 'axios';

class UserStore extends BaseStore {
  @observable
  roles = null;

  @observable
  mealsPlan: any = [];

  @observable
  caloriesPlan: any = [];

  @observable
  userMealPlan: any = {};

  @observable
  userCaloriesPlan: any = {};

  @observable
  profile: IProfile = null;

  @observable
  token: string = null;

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  @computed
  get hasAvatar() {
    return (
      this.profile &&
      this.profile.avatar &&
      this.profile.avatar.indexOf('avatar') >= 0
    );
  }

  @computed
  get avatarURL() {
    if (this.hasAvatar) {
      return process.env.REACT_APP_S3_STORE_URL + '/' + this.profile.avatar;
    }
    return images['avatar.png'].default;
  }

  @computed
  get chats() {
    const subscriptions = this.profile?.subscriptions.map((s) => s.title);
    return (
      subscriptionsChats.find((v) => subscriptions?.includes(v.subscription))
        ?.chats || CHATS_3
    );
  }

  @action
  hasToken() {
    return !!(this.token || localStorage.getItem('auth'));
  }

  @action
  async signup(profile) {
    await UserActions.postSignUp(profile, null, async () => {
      await this.login(
        profile.credentials.phone,
        profile.credentials.password,
        () => (window.location.pathname = '/'),
        null
      );
    });
  }

  @action
  async login(
    username: string,
    password: string,
    success: { (): string; (): void },
    error: null
  ) {
    await AuthActions.login({ username, password }, error, async (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;
      this.setToken(accessToken);

      localStorage.setItem('auth', accessToken);
      localStorage.setItem('auth_r', refreshToken);

      success();
    });
  }

  @action
  async registration(
    email: string,
    password: string,
    success: { (response: AxiosResponse): string; (): void },
    error: null
  ) {
    await AuthActions.postRegistration(
      { email, password },
      error,
      async (response: AxiosResponse) => {
        success(response);
      }
    );
  }

  @action
  async verifyEmail(token: string, success: any, error) {
    await AuthActions.verifyEmail({ token }, error, async () => {
      success();
    });
  }

  @action
  async getProfile() {
    if (this.profile) {
      return;
    }

    this.setToken();
    const user = await UserActions.getProfile();
    const measurements = await UserActions.getMeasurements();
    const startMeasurements = await UserActions.getStartMeasurements();

    const subscriptions = await UserActions.getSubscriptions();

    this.profile = {
      ...user.data,
      startMeasurements: startMeasurements.data,
      measurements: measurements.data.results,
      subscriptions: subscriptions.data.results,
    };

    this.mealsPlan = (await UserActions.getMealPlans()).data.results;

    this.userMealPlan = this.mealsPlan.find(
      (item) => item.id === this.profile.mealPlan
    );

    this.caloriesPlan = (await UserActions.getCaloriesPlans()).data.results;

    this.userCaloriesPlan = this.caloriesPlan.find(
      (item) => item.id === this.profile.caloriesPlan
    );
  }

  @action
  postAvatar(formData, cb) {
    return UserActions.postAvatar(
      formData,
      () => {
        this.stores.uiStore.showDialog(
          'Не удалось обновить фото',
          'Ошибка',
          () => {},
          'popup--style popup--red'
        );
        cb();
      },
      (response) => {
        this.profile = {
          ...this.profile,
          avatar: response.data.avatar,
        };
        cb();
      }
    );
  }

  setToken(token?) {
    this.token = token || localStorage.getItem('auth');
  }

  @action
  logOut() {
    localStorage.removeItem('auth');
    this.token = null;
    this.roles = null;
    this.profile = null;
  }
}

export default UserStore;
