import { action, makeObservable, observable } from 'mobx';
import { IPlanVO } from '../types/IPlanVO';
import { PlanConfigSlimming } from './planConfigSlimming';
import { ISelectedPlanProduct } from '../types/ISelectedPlanProduct';
import { PlanConstants } from '../pages/Plan/constants';
import { IStores } from '../types/IStores';
import { BaseStore } from './baseStore';
import { IAllSelectedPlanProducts } from '../types/ISelectedPlanProducts';
import { PlanConfigSupport } from './planConfigSupport';
import { PlanConfigKeto } from './planConfigKeto';

export class PlanStore extends BaseStore {
  @action
  public getCategories(profileGoal: number): IPlanVO[] {
    // TODO: add constants for profileAim
    switch (profileGoal) {
      case PlanConstants.GOAL_RETENTION:
        return PlanConfigSupport.supportCathegories;
      case PlanConstants.GOAL_KETO:
        return PlanConfigKeto.ketoCathegories;
      case PlanConstants.GOAL_VEGAN:
        return [];
      default:
        return PlanConfigSlimming.slimmingCathegories;
    }
  }

  @observable
  categoryIndex: number = 0;

  @action
  getSelectedCategory(): IPlanVO {
    this.isFirstTimeUnitSelected = {};
    const profileGoal = this.stores.userStore.profile?.mealPlan || 0;
    this.foodIntakeName =
      this.getCategories(profileGoal)[this.categoryIndex || 0].name;
    return this.getCategories(profileGoal)[this.categoryIndex || 0];
  }

  @observable
  allSelectedProducts: IAllSelectedPlanProducts = this.getEmptyAllProducts();

  @observable
  userCalorage: number = PlanConstants.baseCalorage;

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  @action
  setCategory(profileGoal: number, categoryIndex: number): void {
    this.categoryIndex = categoryIndex;
    this.allSelectedProducts = this.getEmptyAllProducts();
  }

  baseGrams: { [key: string]: { [key: string]: number } } = {};
  gramsLeft: { [key: string]: { [key: string]: number } } = {};
  foodIntakeName: string = '';
  isFirstTimeUnitSelected: { [key: string]: { [key: string]: boolean } } = {};
  unitBaseValues: { [key: string]: { [key: string]: number } } = {};

  private getEmptyAllProducts(): IAllSelectedPlanProducts {
    const result = [];
    for (let index: number = 0; index < 3; index++) {
      result.push({
        products: new Map(),
        selectedCalorage: 0,
        hasSelection: false,
        isOverWeight: false,
      });
    }
    return result;
  }

  @action
  modifyProduct(
    foodCategory: number,
    selectedProduct: ISelectedPlanProduct,
    makeRound: boolean = true
  ): void {
    const selectedProducts = this.allSelectedProducts[foodCategory].products;
    if (
      selectedProduct.grams >
      this.baseGrams[this.categoryIndex][selectedProduct.id]
    ) {
      selectedProduct.grams =
        this.baseGrams[this.categoryIndex][selectedProduct.id];
    }
    if (
      selectedProduct.isUnitStep &&
      selectedProduct.grams >=
        this.baseGrams[this.categoryIndex][selectedProduct.id]
    ) {
      this.isFirstTimeUnitSelected[this.foodIntakeName] = {
        [foodCategory]: true,
      };
    } else if (
      selectedProduct.isUnitStep &&
      selectedProduct.grams <
        this.baseGrams[this.categoryIndex][selectedProduct.id]
    ) {
      this.isFirstTimeUnitSelected[this.foodIntakeName] = {
        [foodCategory]: false,
      };
    }
    if (makeRound) {
      selectedProduct.grams = this.cutGramsToStep(selectedProduct);
    }
    if (
      selectedProduct.grams <= 0 ||
      (this.isFirstTimeUnitSelected?.[this.foodIntakeName]?.[foodCategory] &&
        !selectedProduct?.isUnitStep)
    ) {
      selectedProduct.grams = 0;
      selectedProducts.delete(selectedProduct.id);
    } else {
      // if (selectedProducts.size === 0 && !selectedProducts.has(productId)) {
      //     if (makeRound) {
      //         selectedProduct.grams = selectedProduct.product[PlanConstants.prop.grams]
      //         selectedProduct.grams = this.adjustGramsToUserCalorage(selectedProduct.grams);
      //         selectedProduct.grams = this.cutGramsToStep(selectedProduct);
      //     }
      // }
      selectedProducts.set(selectedProduct.id, selectedProduct);
    }
    this.updateTotalCalorage(foodCategory);

    if (
      selectedProducts.size === 1 &&
      selectedProduct.grams / selectedProduct.step ===
        this.unitBaseValues?.[this.categoryIndex]?.[selectedProduct.id]
    ) {
      selectedProduct.grams =
        this.baseGrams[this.categoryIndex][selectedProduct.id];
      this.updateTotalCalorage(foodCategory);
      return;
    }

    if (
      (this.allSelectedProducts[foodCategory].selectedCalorage / 100) * 100 >
        this.userCalorage &&
      this.gramsLeft?.[this.categoryIndex]?.[selectedProduct.id] !==
        undefined &&
      !selectedProduct.isLeastOne
    ) {
      selectedProduct.grams =
        this.gramsLeft[this.categoryIndex][selectedProduct.id];
      this.isFirstTimeUnitSelected[this.foodIntakeName] = {
        [foodCategory]: false,
      };
      this.updateTotalCalorage(foodCategory);
    }
  }

  public getProductId(product: any[]): string {
    return (
      product[PlanConstants.prop.name] + '_' + product[PlanConstants.prop.type]
    );
  }

  private updateTotalCalorage(foodCategory: number): void {
    const selectedProducts = this.allSelectedProducts[foodCategory];
    selectedProducts.selectedCalorage = 0;
    selectedProducts.hasSelection = false;
    selectedProducts.products.forEach((item, productId) => {
      selectedProducts.hasSelection = true;
      selectedProducts.selectedCalorage +=
        (PlanConstants.baseCalorage * item.grams) /
        item.product[PlanConstants.prop.grams];
    });
    //selectedProducts.isOverWeight = selectedProducts.selectedCalorage > this.userCalorage;
  }

  public cutGramsToStep(selectedProduct: ISelectedPlanProduct): number {
    const grams =
      Math.floor(selectedProduct.grams / selectedProduct.step) *
      selectedProduct.step;
    if (!this.baseGrams?.[this.categoryIndex]?.[selectedProduct.id]) {
      this.baseGrams[this.categoryIndex] = {
        ...this.baseGrams[this.categoryIndex],
        [selectedProduct.id]: grams,
      };
    }
    return grams;
  }

  public getGrammsLeft(
    foodCategory: number,
    selectedProduct: ISelectedPlanProduct
  ): number {
    const selectedProducts = this.allSelectedProducts[foodCategory];
    let leftRatio: number =
      (this.userCalorage - selectedProducts.selectedCalorage) /
      PlanConstants.baseCalorage;
    let result =
      Math.floor(
        (selectedProduct.product[PlanConstants.prop.grams] * leftRatio) / 5
      ) * 5;
    if (
      foodCategory !== 1 && //не жиры
      (result <= 5 ||
        this.isFirstTimeUnitSelected?.[this.foodIntakeName]?.[foodCategory])
    ) {
      result = 0;
    }
    if (
      (this.allSelectedProducts[foodCategory].selectedCalorage / 100) * 100 <=
      this.userCalorage
    ) {
      this.gramsLeft[this.categoryIndex] = {
        ...this.gramsLeft[this.categoryIndex],
        [selectedProduct.id]: result,
      };
      selectedProducts.products.forEach((product) => {
        this.gramsLeft[this.categoryIndex][product.id] = product.grams;
      });
    }
    return result;
  }

  public adjustGramsToUserCalorage(grams: number): number {
    return (grams * this.userCalorage) / PlanConstants.baseCalorage;
  }
}
