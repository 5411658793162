import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/useStore';

import s from './Toggle.module.scss';

export const Toggle = observer(() => {
  const { userStore, basketStore } = useStore();

  return (
    <div className={s.container}>
      <div className={s.items}>
        {!userStore?.profile?.isVegan && (
          <NavLink exact activeClassName={s.active} to="/plan">
            план питания
          </NavLink>
        )}

        <NavLink activeClassName={s.active} to="/plan/menu">
          готовое меню
        </NavLink>

        <NavLink activeClassName={s.active} to="/plan/basket">
          корзина
          {basketStore.basketRecipes?.length ? (
            <span className={s.basket}>{basketStore.basketRecipes.length}</span>
          ) : null}
        </NavLink>
      </div>
    </div>
  );
});
