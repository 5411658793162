import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import jwt_decode from 'jwt-decode';
import { IStores } from '../types/IStores';
import { customError } from '../errors/errors';
import { snakeToCamelCase } from 'utils/utils';

export type ApiResponse = AxiosResponse & { message: string; code: number };

const BASE_URL = process.env.REACT_APP_SERVER_HOST;

export default class Connector {
  agent: AxiosInstance;

  constructor(protected stores: IStores) {
    this.agent = axios.create({
      baseURL: BASE_URL,
    });

    this.agent.interceptors.response.use(
      (response) => snakeToCamelCase(response),
      (response) => this.handleUnauthorizedResponse(response)
    );

    this.agent.interceptors.request.use((request) => {
      return this.checkToken(request);
    });
  }

  async checkToken(request: AxiosRequestConfig) {
    const token = localStorage.getItem('auth');
    let decodedToken: any = {};

    if (token) {
      decodedToken = await jwt_decode(token);
    }

    if (decodedToken.exp * 1000 < new Date().getTime()) {
      try {
        const response = await axios({
          method: 'post',
          baseURL: BASE_URL,
          url: '/auth/token/refresh/',
          data: {
            refresh: localStorage.getItem('auth_r'),
          },
        });

        localStorage.setItem('auth', response.data.access);
        localStorage.setItem('auth_r', response.data.refresh);

        return this.addHeaders(request);
      } catch (error) {
        console.log(error);
      }
    } else {
      return this.addHeaders(request);
    }
  }

  async get(
    url: string,
    params: any = null,
    onError?,
    onSuccess = (result) => {
      return result;
    },
    onFinally = () => {}
  ): Promise<ApiResponse> {
    let result: ApiResponse;
    try {
      result = await this.agent.get(`${url}`, { params });
      if (!this.validateStatus(result.status)) {
        await Promise.reject(result);
      }
      onSuccess(result);
    } catch (reason) {
      this.showError(reason, onError);
    } finally {
      onFinally();
    }
    return result;
  }

  async post(
    url: string,
    body: any,
    onError?,
    onSuccess = (result: ApiResponse) => {
      return result;
    },
    onFinally = () => {}
  ): Promise<ApiResponse> {
    let result: ApiResponse;
    try {
      result = await this.agent.post(`${url}`, body);
      if (!this.validateStatus(result.status)) {
        await Promise.reject(result);
      }
      onSuccess(result);
    } catch (reason) {
      this.showError(reason, onError);
    } finally {
      onFinally();
    }
    return result;
  }

  async patch(
    url: string,
    body: any,
    onError?,
    onSuccess = (result: ApiResponse) => {
      return result;
    },
    onFinally = () => {}
  ): Promise<ApiResponse> {
    let result: ApiResponse;
    try {
      result = await this.agent.patch(`${url}`, body);
      if (!this.validateStatus(result.status)) {
        await Promise.reject(result);
      }
      onSuccess(result);
    } catch (reason) {
      this.showError(reason, onError);
    } finally {
      onFinally();
    }
    return result;
  }

  async delete(
    url: string,
    body: any,
    onError?,
    onSuccess = (result: ApiResponse) => {
      return result;
    },
    onFinally = () => {}
  ): Promise<ApiResponse> {
    let result: ApiResponse;
    try {
      result = await this.agent.delete(url, body);
      if (!this.validateStatus(result.status)) {
        await Promise.reject(result);
      }
      onSuccess(result);
    } catch (reason) {
      this.showError(reason, onError);
    } finally {
      onFinally();
    }
    return result;
  }

  validateStatus = (status: number) => {
    return status === 200 || status === 201 || status === 204;
  };

  private addHeaders = (request: AxiosRequestConfig) => {
    const token = localStorage.getItem('auth');

    request.headers = {
      'content-type': 'application/json',
      authorization: token ? 'Bearer ' + token : '',
    };
    return request;
  };

  private handleUnauthorizedResponse = (response) => {
    if (
      response.status === 401 ||
      response?.response?.status === 401 ||
      response.status === 403 ||
      response?.response?.status === 403
    ) {
      localStorage.removeItem('auth');
      localStorage.removeItem('auth_r');
      this.stores.userStore.token = null;
    }
    return response;
  };

  private showError = (error: AxiosError, onError: () => void) => {
    onError
      ? onError()
      : this.stores.uiStore.showResponseError(customError(error));
  };
}
