import { connector } from '../index';

export default class UserActions {
  static async getProfile() {
    return connector.get('/accounts/profile/');
  }

  static async getMeasurements() {
    return connector.get('/measurements/');
  }

  static async getStartMeasurements() {
    return connector.get('/measurements/initial_measurement/');
  }

  static async getSubscriptions() {
    return connector.get('/subscriptions/');
  }

  static async getMealPlans() {
    return connector.get('/nutrition/meal_plans/');
  }

  static async getCaloriesPlans() {
    return connector.get('/nutrition/calories_plans/');
  }

  static async updateProfileInfo(profileInfo, onError, onSuccess) {
    return connector.patch(
      '/accounts/profile/',
      profileInfo,
      onError,
      onSuccess
    );
  }

  //////

  static async checkUser(phone, onError, onSuccess) {
    return connector.post('/user/check', { phone }, onError, onSuccess);
  }

  static async postSignUp(profile, onError, onSuccess) {
    return connector.post('/signup', profile, onError, onSuccess);
  }

  static async postAvatar(formData: FormData, onError, onSuccess) {
    return connector.post('/profile/photo', formData, onError, onSuccess);
  }

  static async loadProgressPhotos() {
    return connector.get('/profile/progressphotos');
  }

  static async addProgressPhoto(formData) {
    return connector.post('/profile/progressphoto', formData);
  }

  static async updateProgressPhoto(photoId: number, formData) {
    return connector.post(`/profile/progressphoto/${photoId}`, formData);
  }

  static async deleteProgressPhoto(photoId: number) {
    return connector.delete('/profile/progressphoto', photoId);
  }
}
