export namespace PlanConstants {
  export const prop = {
    id: 0,
    name: 1,
    type: 2,
    grams: 3,
    image: 4,
    description: 5,
    isOne: 6,
  };

  export const baseCalorage: number = 1600;
  export const baseStep: number = 5;

  export const PROTEIN_BASE: number[] = [144, 94, 82, 73.3];
  export const FAT_BASE: number[] = [64, 62, 123, 53.5];
  export const CARBOHYDRATE_BASE: number[] = [102, 156, 42, 206.7];

  export const GOAL_LOSS: number = 0;
  export const GOAL_RETENTION: number = 1;
  export const GOAL_KETO: number = 2;
  export const GOAL_VEGAN: number = 3;
}
