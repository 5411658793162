import { Link } from 'react-router-dom';
import { getFullPath } from '../../utils/utils';
import { MealTime } from '../../data';
import { inject, observer } from 'mobx-react';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import ProtectedComponent from '../../components/Base/protected';
import { BasketLabel } from '../../pages/Plan/components/Menu/basketLabel';
import { WishLabel } from '../../pages/Plan/components/Menu/wishLabel';
import { ProgressLoader } from 'components/Utils/componentUtils';
import './Recipe.css';

const foodClassNames = ['', 'green', 'orange'];

@inject('basketStore', 'recipeStore', 'uiStore', 'userStore')
@observer
class Recipe extends ProtectedComponent {
  slider: any;
  protected sliderDataType: string = 'similar';

  async componentDidMount() {
    const id = this.props.history.location.pathname.split('/').pop();
    this.props.recipeStore.selectRecipe(id);
    await super.componentDidMount();
  }

  componentWillUnmount() {
    this.props.recipeStore.resetSelected();
  }

  onRecipeChangeCount(event) {
    this.props.recipeStore.changeCount(event.target.value);
  }

  render() {
    window.scroll({ top: 0 });
    const { recipeStore, uiStore, basketStore } = this.props;
    const recipe = recipeStore?.selectedRecipe;

    if (!recipe) {
      return <ProgressLoader open={true} />;
    }
    return (
      <div>
        <div
          className="recipe_topblock"
          style={{
            backgroundImage: `url(${recipe.image})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat 50% 50%',
          }}
        />
        <div className="container">
          <a
            className={
              this.props.history.length < 3 ? 'back back--disabled' : 'back'
            }
            onClick={() => this.props.history.goBack()}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="40"
                height="40"
                rx="20"
                fill="url(#paint0_linear_1551_574)"
              />
              <path
                d="M22 13L15 20L22 27"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1551_574"
                  x1="0"
                  y1="0"
                  x2="42.4419"
                  y2="2.7849"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F98C45" />
                  <stop offset="1" stopColor="#F55555" />
                </linearGradient>
              </defs>
            </svg>
          </a>
          <div className="recipe">
            <BasketLabel recipe={recipe} />
            <WishLabel recipe={recipe} />
            <div className="recipe__top">
              {MealTime[recipe.mealTime]}{' '}
              {recipe.timeToCook ? '|' + recipe.timeToCook : null}
            </div>
            <div className="title">{recipe.title}</div>
            <div className="recipe__parts">
              {recipe.components.length} ингредиентов
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div className="recipe__char">Ккал</div>
                    {recipe.calorie}
                  </td>
                  <td>
                    <div className="recipe__char">Белки</div>
                    {recipe.proteins} г
                  </td>
                  <td>
                    <div className="recipe__char">Жиры</div>
                    {recipe.fats} г
                  </td>
                  <td>
                    <div className="recipe__char">Углеводы</div>
                    {recipe.carbohydrates} г
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="title">
            Ингредиенты на
            <select
              name="portion"
              className="portion"
              onChange={this.onRecipeChangeCount.bind(this)}
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </select>
            {recipeStore.count === 1
              ? 'порцию'
              : recipeStore.count === 2 ||
                recipeStore.count === 3 ||
                recipeStore.count === 4
              ? 'порции'
              : 'порций'}
          </div>
          <div className="sostav">
            {[...recipe.components].map((food) => {
              return (
                <table key={food.id}>
                  <tbody>
                    <tr>
                      <td>
                        <div className="sostav__image">
                          <img src={food.image} alt="Ингридиенты" />
                        </div>
                      </td>
                      <td>{food.title}</td>
                      <td>
                        {food.weight
                          ? food.isIndivisible
                            ? food.quantity * recipeStore.count + ' шт.'
                            : food.weight * recipeStore.count + ' г'
                          : 'по вкусу'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          </div>
          {recipe.cookingSteps && recipe.cookingSteps.length > 0 ? (
            <div>
              <div className="title">Приготовление</div>
              <div className="steps">
                {recipe.cookingSteps.map((step: string, index: number) => {
                  return (
                    <div className="steps__block" key={index}>
                      <div className="steps__number">{index + 1} шаг</div>
                      {step}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className="title">Похожие рецепты</div>

          {recipeStore.similarRecipes ? (
            <div style={{ height: '300px' }}>
              <AutoSizer>
                {({ width, height }) => (
                  <FixedSizeList
                    className="menuSlider"
                    layout="horizontal"
                    height={height}
                    width={width}
                    itemSize={310}
                    itemCount={recipeStore.similarRecipes.length}
                    itemData={recipeStore.similarRecipes}
                  >
                    {(props) => {
                      const { data, index, i, style } = props;
                      const recipe = data[index];
                      return (
                        <div
                          className="item"
                          key={'similarRecipe' + i}
                          style={style}
                        >
                          <div className="recipe__item">
                            <Link
                              to={'/recipe/' + recipe.id}
                              onClick={(e) =>
                                recipeStore.selectRecipe(recipe.id)
                              }
                              className="recipe__image"
                              style={{
                                backgroundImage: `url(${getFullPath(
                                  '/images/smallrecipes/' + recipe.image,
                                  false
                                )})`,
                              }}
                            >
                              {' '}
                            </Link>
                            <Link
                              to={'/recipe/' + recipe.id}
                              onClick={(e) =>
                                recipeStore.selectRecipe(recipe.id)
                              }
                              className="recipe__text"
                            >
                              <span className="recipe__desc">
                                {MealTime[recipe.mealTime]}
                                {recipe.timeToCook
                                  ? '|' + recipe.timeToCook
                                  : null}
                              </span>
                              <span className="recipe__title">
                                {recipe.title}
                              </span>
                              <span className="recipe__links">
                                {recipe.components.map((food, index) => {
                                  return index < 3 ? (
                                    <span
                                      className={foodClassNames[index % 3]}
                                      key={this.sliderDataType + i + food.title}
                                    >
                                      {food.title}
                                    </span>
                                  ) : null;
                                })}
                                {!recipe.expanded && recipe.foods.length > 3 ? (
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    className={foodClassNames[0]}
                                    key={this.sliderDataType + i + 'more'}
                                  >
                                    ...
                                  </span>
                                ) : null}
                                {recipe.foods.map((food, index) => {
                                  return recipe.expanded && index >= 3 ? (
                                    <span
                                      className={foodClassNames[index % 3]}
                                      key={this.sliderDataType + i + food.title}
                                    >
                                      {food.title}
                                    </span>
                                  ) : null;
                                })}
                              </span>
                            </Link>
                          </div>
                        </div>
                      );
                    }}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
          ) : (
            <div className="notfound notfound__title--daily">
              <div className="notfound__title">Нет похожих рецептов</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Recipe;
