import { images, ProgressLoader } from '../../components/Utils/componentUtils';
import { PlanCategories } from './components/PlanCategories';
import { inject, observer } from 'mobx-react';
import { PlanStore } from '../../stores/planStore';
import { Avatar, Tab, Tabs } from '@material-ui/core';
import UserStore from '../../stores/userStore';
import ProtectedComponent from '../../components/Base/protected';
import { ALLOWED_FOODS } from '../../data';
import { UiStore } from '../../stores/uiStore';
import { Redirect } from 'react-router-dom';
import s from './Plan.module.scss';
import { PlanHeader } from './components/PlanHeader';

@inject('planStore', 'userStore', 'basketStore', 'uiStore', 'recipeStore')
@observer
class Plan extends ProtectedComponent {
  render() {
    const planStore: PlanStore = this.props.planStore;
    const userStore: UserStore = this.props.userStore;
    const uiStore: UiStore = this.props.uiStore;

    if (!userStore.token || uiStore.loading) {
      return <ProgressLoader open={true} />;
    }

    if (userStore?.profile?.isVegan) {
      return <Redirect to={'/plan/menu'} />;
    }
    return (
      <div className="container">
        <PlanHeader {...this.props} />

        {true ? (
          <div>
            <Tabs
              value={planStore.categoryIndex}
              onChange={(e, newValue) => {
                planStore.setCategory(userStore.profile.mealPlan, newValue);
              }}
              aria-label="simple tabs example"
            >
              <Tab
                tabIndex={0}
                label={'Завтрак'}
                icon={
                  <Avatar alt="категория" src={images['cat1.png'].default} />
                }
              />
              <Tab
                tabIndex={1}
                label={'Обед'}
                icon={
                  <Avatar alt="категория" src={images['cat2.png'].default} />
                }
              />
              <Tab
                tabIndex={2}
                label={'Перекус'}
                icon={
                  <Avatar alt="категория" src={images['cat3.png'].default} />
                }
              />
              <Tab
                tabIndex={3}
                label={'Ужин'}
                icon={
                  <Avatar alt="категория" src={images['cat4.png'].default} />
                }
              />
            </Tabs>

            <PlanCategories planVO={planStore.getSelectedCategory()} />

            {/*<a className="download" href="/" target="_blank">скачать план питания в pdf-формате</a>*/}
            <div className={s.title}>
              Если не нашли любимого продукта в плане питания?
            </div>
            <div className="note">
              Если вашего продукта нет в “разрешенных” уточните в чате “Вопросы”
              у Куратора проекта, есть ли возможность встроить в ваш план
              питания любимый продукт.
              <a href={ALLOWED_FOODS} className="white_btn">
                Разрешенные продукты
              </a>
              <img
                className="note__img"
                src={images['sticker1.png'].default}
                alt="sticker"
              />
            </div>
          </div>
        ) : (
          <div className="note">
            Для выбранного вами плана калькулятор плана питания пока недоступен
          </div>
        )}
      </div>
    );
  }
}

export default Plan;
