import { action, makeObservable, observable } from 'mobx';
import { IRecipe } from '../types/IRecipe';
import { BaseStore } from './baseStore';
import { IStores } from '../types/IStores';
import { ISearchItem } from '../types/ISearchItem';
import { SessionStorageUtil } from '../components/Utils/sessionStorageUtil';
import RecipeActions from '../connector/recipeActions';

class RecipeStore extends BaseStore {
  readonly foodClassNames = ['', 'green', 'orange'];

  locked = false;

  @observable
  searchItems: ISearchItem[] = [];

  @observable
  recipes: IRecipe[] = null;

  @observable
  favoriteRecipes: any[] = null;

  @observable
  categoryRecipes: IRecipe[][] = [];

  @observable
  dailyRecipes: IRecipe[] = null;

  @observable
  selectedId = null;

  @observable
  selectedRecipe = null;

  @observable
  count = 1;

  @observable
  selectedCategory =
    Number(sessionStorage.getItem('recipes.selectedCategory')) || 0;

  @observable
  favoriteIds: number[] = [];

  @observable
  similarRecipes: IRecipe[] = null;

  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  addToSearchString(value: string): void {
    this.searchItems.push({
      value,
      key: value.toLowerCase(),
    });
  }

  removeSearchString(value: string): void {
    const index: number = this.searchItems.findIndex((item) => {
      return item.value === value;
    });
    if (index >= 0) {
      this.searchItems.splice(index, 1);
    }
  }

  @action
  async getFavoriteRecipes() {
    this.favoriteRecipes = (await RecipeActions.getFavorite()).data.results;

    this.favoriteIds = this.favoriteRecipes.map((item) => item.id);
  }

  @action
  async loadAll() {
    if (this.locked) {
      return;
    }

    this.locked = true;

    this.recipes = (
      await RecipeActions.getAll(this.selectedCategory)
    ).data.results;

    this.getFavoriteRecipes();

    this.stores.basketStore.basketRecipes ??
      this.stores.basketStore.loadBasket();

    if (this.selectedId) {
      await this.loadSelectedRecipe();
    }

    const categoryRecipes: IRecipe[][] = new Array(4)
      .fill(undefined)
      .map(() => []);

    if (this.recipes.length) {
      for (const recipe of this.recipes) {
        categoryRecipes[recipe.mealTime].push(recipe);
      }

      // const dailyRecipes = [];
      // const dayStamp = Math.floor(Date.now() / (1000 * 60 * 60 * 24));

      // const allBreakfasts = categoryRecipes[0].sort(
      //   (rec1, rec2) => rec1.id - rec2.id
      // );

      // const allLunches = categoryRecipes[1].sort(
      //   (rec1, rec2) => rec1.id - rec2.id
      // );

      // const allBranches = categoryRecipes[2].sort(
      //   (rec1, rec2) => rec1.id - rec2.id
      // );

      // const allDinners = categoryRecipes[3].sort(
      //   (rec1, rec2) => rec1.id - rec2.id
      // );

      // ALERT
      // dailyRecipes.push(allBreakfasts[dayStamp % allBreakfasts.length]);
      // dailyRecipes.push(allLunches[dayStamp % allLunches.length]);
      // dailyRecipes.push(allBranches[dayStamp % allBranches.length]);
      // dailyRecipes.push(allDinners[dayStamp % allDinners.length]);
      // dailyRecipes.forEach(
      //   (recipe) => (recipe.isFavorite = this.favoriteIds.includes(recipe.id))
      // );

      // this.dailyRecipes = dailyRecipes;
      this.categoryRecipes = categoryRecipes;
      this.locked = false;
    }
  }

  @action
  async switchIsFavorite(recipe) {
    const isFavorite = this.favoriteIds.includes(recipe.id);
    if (isFavorite) {
      this.favoriteIds = this.favoriteIds.filter((id) => id !== recipe.id);
      recipe.isFavorite = false;
      await RecipeActions.removeFromFavorite(
        recipe.id,
        () => {
          this.stores.uiStore.showDialog(
            'Ошибка удаления любимых рецептов',
            'Ошибка'
          );
        },
        async () => {
          await this.getFavoriteRecipes();
        }
      );
    } else {
      this.favoriteIds = this.favoriteIds.concat(recipe.id);
      recipe.isFavorite = true;
      await RecipeActions.addToFavorite(
        recipe.id,
        () => {
          this.stores.uiStore.showDialog(
            'Ошибка добавления любимых рецептов',
            'Ошибка'
          );
        },
        async () => {
          await this.getFavoriteRecipes();
        }
      );
    }
  }

  @action
  async setCategory(category) {
    this.selectedCategory = category;
    SessionStorageUtil.setInt(
      SessionStorageUtil.RECIPES_SELECTED_CATEGORY,
      category
    );

    await this.loadAll();
    this.locked = false;
  }

  @action
  loadSimilarRecipes(id) {
    return RecipeActions.getSimilarRecipes(id);
  }

  async selectRecipe(id) {
    this.selectedId = id;
    if (this.selectedRecipe && this.selectedRecipe !== id) {
      await this.loadSelectedRecipe();
    }
  }

  @action
  changeCount(count) {
    this.count = count;
  }

  @action
  resetSelected() {
    this.selectedId = null;
    this.selectedRecipe = null;
    this.count = 1;
  }

  @action
  async loadSelectedRecipe() {
    const response = await RecipeActions.getRecipe(this.selectedId);
    this.selectedRecipe = response.data;
  }
}

export default RecipeStore;
