import { BaseStore } from "./baseStore";
import { IStores } from "../types/IStores";
import { action, makeObservable, observable } from "mobx";
import { ContentData, Section, sectionData, sections } from "../pages/Support/data";

export class SupportStore extends BaseStore {
    @observable
    public isVisibleMenu = true;

    @observable
    public currentSection: Section;

    @observable
    public currentContent: ContentData;

    constructor(stores: IStores) {
        super(stores);
        makeObservable(this);
    }

    @action
    public changeVisibleMenu() {
        this.isVisibleMenu = !this.isVisibleMenu;
    }

    @action
    public setCurrentSection(index: number) {
        this.currentSection = sections[index];
    }

    @action
    setCurrentContent(index: number) {
        this.currentContent = sectionData[this.currentSection.id]?.items?.[index];
    }

    @action
    public onSectionClick(index: number) {
        this.initializeSection();
        this.currentSection = sections[index];
        this.initializeSectionItems();
        this.changeVisibleMenu();
    }

    @action
    public onContentButtonClick(contentIndex: number) {
        this.currentContent = sectionData[this.currentSection.id]?.items?.[contentIndex];
    }

    @action
    public goBack() {
        if (this.currentContent?.isFirstSlide) {
            this.changeVisibleMenu();
            this.currentSection = undefined;
            return;
        }
        this.currentContent = sectionData[this.currentSection.id]?.items?.[this.currentContent.prevIndex];
    }

    @action
    public goForward() {
        if (this.currentContent.nextIndex) {
            this.currentContent = sectionData[this.currentSection.id]?.items?.[this.currentContent.nextIndex];
            return;
        }
        this.setCurrentSection(this.currentSection.nextIndex ?? 0);
        this.initializeSectionItems();
    }

    @action
    public goMenu() {
        this.changeVisibleMenu();
        this.currentSection = undefined;
    }

    @action
    public goFirstSlideContent() {
        this.currentContent = sectionData[this.currentSection.id]?.items[0];
    }

    private initializeSection() {
        sections.forEach((item, index, array) => {
            item.nextIndex = index + 1 <= array.length - 1 ? index + 1 : null;
            item.prevIndex = index - 1 >= 0 ? index - 1 : null;
        });
    }

    private initializeSectionItems() {
        if (this.currentSection) {
            sectionData[this.currentSection.id]?.items?.forEach(
                (item, index, array) => {
                    item.nextIndex = index + 1 <= array.length - 1 ? index + 1 : null;
                    item.prevIndex = index - 1 >= 0 ? index - 1 : null;
                    item.currentIndex = index;
                    item.isFirstSlide = index === 0;
                    item.isLastSlide = index === array.length - 1;
                }
            );
            this.currentContent = sectionData[this.currentSection.id]?.items[0];
        }
    }
}