import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import BaseComponent from 'components/Base/base';
import { ComponentProgress } from 'components/Utils/componentUtils';
import AuthActions from 'connector/authActions';
import { inject, observer } from 'mobx-react';

@inject('uiStore', 'userStore')
@observer
class Registration extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      progress: false,
      showPassword: false,
    };
  }

  async handleSignIn(e: { preventDefault: () => void }) {
    e.preventDefault();
    this.setState({ progress: true });

    try {
      await this.props.userStore.registration(
        this.state.email,
        this.state.password,
        async (response) => {
          this.setState({
            email: '',
            password: '',
            progress: false,
          });

          const { data, status } = response;

          if (status === 201) {
            this.props.uiStore.showDialog(
              `На почту ${data.email} отправлено письмо с дальнейшей инструкцией по активации учетной записи.`,
              'Поздравляем!',
              () => {
                this.props.history.push('/login');
              },
              'popup--style popup--blue'
            );
          }
        }
      );
    } catch (error) {}
  }

  handleEmailChange(e: { target: { value: any } }) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e: { target: { value: any } }) {
    this.setState({ password: e.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    return (
      <div className="text-center userform__wrapper">
        <div className="userform">
          <h1 className="title">Регистрация</h1>
          <div className="profil">
            <form onSubmit={this.handleSignIn.bind(this)}>
              <input
                type="text"
                name="email"
                value={this.state.username}
                onChange={this.handleEmailChange.bind(this)}
                placeholder="Email"
              />
              <input
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Пароль"
                onChange={this.handlePasswordChange.bind(this)}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword.bind(this)}
                edge="end"
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {!this.state.progress ? (
                <input
                  className="btn"
                  type="submit"
                  name="submit"
                  value="Регистрация"
                />
              ) : (
                <ComponentProgress />
              )}
            </form>
            <div
              className="userform__link"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <div>
                <span>Есть учетная запись?</span>&nbsp;
                <a onClick={() => this.props.history.push('/login')}>Войти</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
