import { BaseStore } from './baseStore';
import { action, makeObservable, observable } from 'mobx';
import { IAlertDialogVO } from '../types/IAlertDialogVO';
import { IStores } from '../types/IStores';
import React from 'react';
import { ErrorDescription } from '../errors/errors';

export class UiStore extends BaseStore {
  constructor(stores: IStores) {
    super(stores);
    makeObservable(this);
  }

  @observable
  trainModal: { [id: string]: boolean } = {};

  @observable
  dialog: IAlertDialogVO = null;

  @observable
  loading: boolean = false;

  @action
  loadingOn() {
    this.loading = true;
  }

  @action
  loadingOff() {
    this.loading = false;
  }

  @action
  showDialog(
    message?: string | React.ReactNode,
    title?: string,
    onClose = () => {},
    classes?: string,
    bottom?: string | React.ReactNode
  ) {
    this.dialog = {
      message: message ? message : 'Неизвестная ошибка',
      title: title ? title : 'Внимание',
      isOpen: true,
      onClose,
      classes: classes ? classes : '',
      bottom: bottom ? bottom : 'ХОРОШО',
    };
  }

  @action
  showError(message) {
    this.showDialog(
      message,
      'Ошибка',
      () => (window.location.pathname = '/'),
      undefined,
      'На главную'
    );
  }

  @action
  showResponseError(errorDescription: ErrorDescription) {
    const { message, title, shouldReload } = errorDescription;
    this.showDialog(
      message,
      title || 'Ошибка',
      shouldReload ? () => window.location.reload() : null,
      'popup--style popup--red'
    );
  }

  @action
  showInternalServerError() {
    this.showDialog(
      'Попробуйте позже',
      'Произошла ошибка на сервере',
      () => {
        window.location.reload();
      },
      'popup--style popup--red'
    );
  }

  @action
  showUnableToCallError() {
    this.showDialog(
      'Не удалось получить запрашиваемый ресурс',
      'Отказано в доступе',
      () => (window.location.pathname = '/'),
      'popup--style popup--red'
    );
  }

  @action
  showDevelopMessage() {
    this.showDialog('В текущей версии кабинета этот функционал недоступен');
  }

  @action
  clearDialog() {
    this.dialog = null;
  }
}
