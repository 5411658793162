import { inject, observer } from 'mobx-react';
import {
  ComponentProgress,
  PhoneNumber,
} from '../../components/Utils/componentUtils';
import BaseComponent from '../../components/Base/base';
import Plan from '../Plan';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

@inject('userStore', 'uiStore')
@observer
class Login extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      showPassword: false,
      progress: false,
    };
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    props.uiStore.loadingOff();
  }

  async handleSignIn(e) {
    e.preventDefault();
    this.props.userStore.logOut();
    this.setState({ progress: true });

    await this.props.userStore.login(
      this.state.username,
      this.state.password,
      async () => {
        this.setState({ progress: false });
        const { pathname } = this.props.location;
        this.props.history.push(
          pathname === '/login' || !pathname ? '/' : pathname
        );
      },
      () => {
        this.setState({
          progress: false,
        });
        this.props.uiStore.showDialog(
          'Для восстановления пароля воспользуйтесь ссылкой внизу страницы.',
          'Неправильный логин или пароль',
          () => {},
          'popup--style popup--red'
        );
      }
    );
  }

  handlePasswordChange(e) {
    e.preventDefault();
    this.setState({ password: e.target.value });
  }

  handlePhoneChange(event: { target: { value: any } }) {
    if (event.target.value) {
      this.setState({ username: event.target.value });
    }
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    return this.props.userStore.hasToken() ? (
      <Plan />
    ) : (
      <div className="text-center userform__wrapper">
        <div className="userform">
          <h1 className="title">Вход</h1>
          <div className="profil">
            <form onSubmit={this.handleSignIn.bind(this)}>
              <input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handlePhoneChange}
                placeholder="Email или телефон"
              />
              <input
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Пароль"
                onChange={this.handlePasswordChange.bind(this)}
              />
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword.bind(this)}
                edge="end"
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              {!this.state.progress ? (
                <input
                  className="btn"
                  type="submit"
                  name="submit"
                  value="Войти"
                  onClick={this.handleSignIn.bind(this)}
                />
              ) : (
                <ComponentProgress />
              )}
            </form>
            <div
              className="userform__link"
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <a onClick={() => this.props.history.push('/reset-password')}>
                Забыли пароль?
              </a>
              <a onClick={() => this.props.history.push('/registration')}>
                Создать учетную запись
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
