import BaseComponent from './base';
import RecipeStore from '../../stores/recipeStore';
import UserStore from '../../stores/userStore';
import { UiStore } from '../../stores/uiStore';
import { hasAccessRole } from '../../utils/utils';
import { Role } from '../../data';

class ProtectedComponent extends BaseComponent {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const userStore: UserStore = this.props.userStore;
    const token = localStorage.getItem('auth');

    if (token) {
      await (this.props.recipeStore as RecipeStore).loadAll();
      await userStore.getProfile();
    } else {
      window.location.href = '/login';
    }

    (this.props.uiStore as UiStore).loadingOff();
    window.scroll({ top: 0 });
  }
}

export default ProtectedComponent;
