import React, {Component} from "react";
import {ComponentProgress} from "../../../components/Utils/componentUtils";
import {inject, observer} from "mobx-react";
import {UiStore} from "../../../stores/uiStore";
import UserStore from "../../../stores/userStore";

@inject("uiStore")
@inject("userStore")
@observer
export class AvatarPost extends Component<any, any> {

    private fileRef = React.createRef<any>();
    private formRef = React.createRef<any>();

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        const uiStore: UiStore = this.props.uiStore;
        const userStore: UserStore = this.props.userStore;
        return (
            this.state.loading ? <div style={{height: 190}}><ComponentProgress/></div> :
            <div className="profil__avatar">
                <img className="avatar" width="94" src={userStore.avatarURL} alt="avatar"/>
                <form ref={this.formRef} action="/" encType="multipart/form-data" method="post">
                    <label htmlFor="avatar" className="input_file btn" onClick={ () => {
                        this.fileRef.current.click();
                    }}>
                        {userStore.hasAvatar ? "Изменить фотографию" : "Добавить фотографию" }
                    </label>
                    <input ref={this.fileRef} type="file" name="image" accept="image/*" style={{visibility:"hidden", display: "none"}}
                           onChange={async (event) => {
                               this.setState({loading: true});

                               const files = event.target.files;
                               if (files.length !== 1) {
                                   uiStore.showDialog("Выберите только один файл")
                               } else if (files[0].type.indexOf("image") !== 0) {
                                   uiStore.showDialog("Выберите файл с изображением: jpg, jpeg, gif, png");
                               } else {
                                   const formData: FormData = new FormData();
                                   formData.append("image", files[0]);
                                   await userStore.postAvatar(formData, () => this.setState({loading: false}));
                               }
                           }}
                    />
                </form>
            </div>
        );
    }
}