import { UiStore } from '../stores/uiStore';
import UserStore from '../stores/userStore';
import { Role } from '../data';
import { IProfile } from 'types/IProfile';

export function appendChild(dom, src, type, cb: any = null, timeout = 0) {
  const child = dom.createElement('script');
  child.src = src;
  child.type = type;
  child.async = true;
  setTimeout(() => {
    dom.body.appendChild(child);
    if (cb) {
      cb();
    }
  }, timeout);
}

export function roleErrorPopup(uiStore: UiStore) {
  uiStore.showDialog(
    'Если вы считаете, что это ошибка, обратитесь, пожалуйста, к куратору.',
    'У вас нет доступа к данной информации',
    null,
    'popup--style popup--red'
  );
}

export function hasAccessRole(userStore: UserStore, role: number): boolean {
  const userRoles: number[] = userStore.roles;
  return (
    userRoles?.length &&
    (userRoles.includes(role) || // user has role
      userRoles.includes(Role.ADMIN) || // user is admin
      (userRoles.includes(Role.CURATOR) && role !== Role.ADMIN)) // user is curator and role is not for admin only
  );
}

export function importAll(r) {
  const images = {};
  r.keys().map((item, index) => {
    return (images[item.replace('./', '')] = r(item));
  });
  return images;
}

export function numberFormat(num) {
  return num.toString().replace(',', '.');
}

export function getFullName(user: IProfile) {
  return user.lastName + ' ' + user.firstName + ' ' + user.patronymic;
}

export function getFullPath(path, isAbsolute) {
  return process.env.REACT_APP_S3_SITE_URL + path;
  // return isAbsolute ? path : process.env.REACT_APP_SERVER_HOST + "/files" + path;
}

export function validateResponse(response) {
  return response && response.status >= 200 && response.status <= 202;
}

export function getSliderSettings() {
  return {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 10000,
    pauseOnFocus: true,
    swipeToSlide: true,
  };
}

export function snakeToCamelCase(obj: {}) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeToCamelCase);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = key.replace(/_(\w)/g, (_, letter) =>
      letter.toUpperCase()
    );
    acc[camelCaseKey] = snakeToCamelCase(obj[key]);
    return acc;
  }, {});
}
