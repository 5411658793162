import MuiAlert from '@material-ui/lab/Alert';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import React, {forwardRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {SlideProps, Snackbar} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {importAll} from "../../utils/utils";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MuiPhoneNumber from "material-ui-phone-number";

export const images = importAll((require as any).context('../../images', false, /\.(png|jpe?g|svg)$/));

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center"
    }
}));

export const Transition = forwardRef<any, SlideProps>(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function DialogBar(props: any) {
    const classes = useStyles();
    return <AppBar className={classes.appBar}>
        <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
                <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {props.title}
            </Typography>
            <Button autoFocus color="inherit" onClick={props.save}>
                сохранить
            </Button>
        </Toolbar>
    </AppBar>
}

export function DialogButtons(props:any) {
    return <div>
        <Button onClick={props.save} style={{width: "30%", maxWidth: "400px", margin: "20px 0 0 10%", backgroundColor: "#3f51b5", color: "white"}}>
            сохранить
        </Button>
        <Button onClick={props.reset} style={{width: "30%", maxWidth: "400px", margin: "20px 0 0 5%", backgroundColor: "#3f51b5", color: "orange"}}>
            сбросить
        </Button>
    </div>
}

export function DialogSnackBar(props:any) {
    return <Snackbar open={props.open} autoHideDuration={1000} onClose={props.close}>
        <Alert severity={props.severity}>
            {props.text}
        </Alert>
    </Snackbar>
}

export function Progress() {
    const classes = useStyles();
    return <Backdrop open={true} className={classes.backdrop}>
        <CircularProgress color="inherit"/>
    </Backdrop>
}

export function ProgressLoader(props) {
    const classes = useStyles();
    return <Backdrop open={props.open} className={classes.backdrop} style={{position: "fixed", backgroundColor: "transparent"}}>
        <CircularProgress color="secondary"/>
    </Backdrop>
}

export function BaseDialog(props: any) {
    const {message, title, isOpen, handleClose, classes, bottom} = props;
    return <Dialog
        style={{paddingTop: "3em"}}
        onClose={() => handleClose()}
        open={isOpen} className={classes}>
        <DialogTitle disableTypography style={{paddingTop: "3em"}}>
            <Typography variant="h6">{title}</Typography>
            <IconButton className={"alert-close"} aria-label="close" onClick={() => handleClose()}>
                <CloseIcon  />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            <Typography gutterBottom>
                {message}
            </Typography>
        </DialogContent>
        <DialogContent dividers onClick={() => handleClose()} className="popup__bottom">
            <Typography gutterBottom>
                {bottom}
            </Typography>
        </DialogContent>
    </Dialog>
}

export function ComponentDialog(props) {
    return <Dialog open={props.open}>
        <DialogTitle disableTypography >
            <Typography variant="h6">{props.title}</Typography>
            <IconButton className={"alert-close"} aria-label="close" onClick={() => props.handleClose()}>
                <CloseIcon  />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers children={props.children}/>
    </Dialog>
}

export function PendingError(props) {
    const {error, message, onClose} = props;
    return error ? <BaseDialog message={message} isOpen={true} handleClose={onClose} title={"Ошибка"}/> : <Progress/>;
}

export function ComponentProgress() {
    const classes = useStyles();
    return <div className={classes.circularProgress}><CircularProgress color="secondary" /></div>
}

export function PhoneNumber(props) {
    return <MuiPhoneNumber
        name={props.name}
        defaultCountry={'by'}
        preferredCountries={['by', 'ru', 'ua', 'kz']}
        value={props.value}
        onChange={props.onChange}
        className={props?.className}
        {...props}
    />
}