import {connector} from "../index";

export default class MeasurementActions {
    static async postMeasurement(measurement) {
        return connector.post('/measurement', measurement);
    }

    static async deleteMeasurement(id) {
        return connector.delete("/measurement", id);
    }

    static async getMeasurement(id: number) {
        return connector.get('/measurement/' + id);
    }

    static async getMeasurements(range: string) {
        return connector.get('/measurement', {range});
    }

    static async getMeasurementsYear() {
        return connector.get('/measurement', {range: "year"});
    }

    static async getMeasurementsMonth() {
        return connector.get('/measurement', {range: "month"});
    }

    static async getMeasurementsDay() {
        return connector.get('/measurement', {range: "day"});
    }
}