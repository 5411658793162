import {Component} from "react";
import { install } from 'resize-observer';

if (!window.ResizeObserver) {
    console.log("Install ResizeObserver...");
    install();
}

class BaseComponent extends Component<any, any> {

    async componentDidMount() {
        this.props.uiStore.loadingOff();
    }

}

export default BaseComponent;