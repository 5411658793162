import { Component } from 'react';
import {
  ProgressLoader,
  images,
} from '../../../../components/Utils/componentUtils';
import { inject, observer } from 'mobx-react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import { PlanConstants } from '../../constants';
import UserStore from '../../../../stores/userStore';
import { Toggle } from '../../../../components/Toggle/Toggle';

@inject('uiStore', 'basketStore', 'recipeStore', 'userStore', 'uiStore')
@observer
export class PlanHeader extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  private getRoundGrams(planId: number, baseAmount: number): number {
    return Math.round((planId * baseAmount) / PlanConstants.baseCalorage);
  }

  render() {
    const userStore: UserStore = this.props.userStore;

    if (!userStore.token || this.props.uiStore.loading) {
      return <ProgressLoader open={true} />;
    }

    return (
      <div>
        <Toggle />
        <div className="plan__norm">
          <div className="plan__normtitle">
            <span>ПЛАН ПИТАНИЯ</span>{' '}
            <span>{userStore.userMealPlan?.title}</span>
          </div>
          <div className="title">
            Твоя суточная норма:&nbsp;
            <span className="red">
              {userStore.userCaloriesPlan?.calories &&
                `${userStore.userCaloriesPlan?.calories} ккал`}
            </span>
          </div>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className="recipe__char">Белки</div>
                  {userStore.profile?.mealPlan
                    ? `${this.getRoundGrams(
                        userStore.userCaloriesPlan?.calories,
                        PlanConstants.PROTEIN_BASE[userStore.profile?.mealPlan]
                      )} г`
                    : '-'}
                </td>
                <td>
                  <div className="recipe__char">Жиры</div>
                  {userStore.profile?.mealPlan
                    ? `${this.getRoundGrams(
                        userStore.userCaloriesPlan?.calories,
                        PlanConstants.FAT_BASE[userStore.profile?.mealPlan]
                      )} г`
                    : '-'}
                </td>
                <td>
                  <div className="recipe__char">Углеводы</div>
                  {userStore.profile?.mealPlan
                    ? `${this.getRoundGrams(
                        userStore.userCaloriesPlan?.calories,
                        PlanConstants.CARBOHYDRATE_BASE[
                          userStore.profile?.mealPlan
                        ]
                      )} г`
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {userStore.userCaloriesPlan?.calories <= 1200 ? (
          <div className="infoblock">
            Мы категорически не рекомендуем использовать план питания менее 1200
            ккал. Это может серьезно навредить вашему здоровью.
          </div>
        ) : (
          ''
        )}
        {userStore.userMealPlan < 3 ? (
          <div>
            <div className="note">
              Нажмите на кнопку, чтобы посмотреть короткое видео “Как
              пользоваться Планом-конструктором?“
              {/*<a href="/" className="white_btn">как пользоваться</a>*/}
              <a onClick={this.openModal} className="white_btn">
                как пользоваться
              </a>
              <img
                className="note__img"
                src={images['sticker3.png'].default}
                alt="sticker"
              />
            </div>
            <ModalVideo
              channel="youtube"
              autoplay
              isOpen={this.state.isOpen}
              videoId="QWJHJuWZ04Q"
              onClose={() => this.setState({ isOpen: false })}
            />
            <div className="title">Выберите приём пищи</div>
          </div>
        ) : null}
      </div>
    );
  }
}
