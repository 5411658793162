import s from "./Content.module.scss";
import { useStore } from "../../../../stores/useStore";
import { observer } from "mobx-react";
import ModalVideo from 'react-modal-video';

export const Content = observer(() => {
    const { supportStore, uiStore } = useStore();
    const { currentContent } = supportStore;

    return (
        <div className={s.container}>
            <div className={s.title}>{currentContent.contentTitle}</div>
            {currentContent.description?.map((item, index) => {
                return <div
                    className={s.blockInner}
                    key={index}
                >
                    {item}
                </div>;
            })}
            {currentContent.videoHref && <>
                <div className={s.video}>
                    <a
                        onClick={() => {
                            uiStore.trainModal[currentContent.videoHref] = true;
                        }}
                    >
                        <svg className="excercisePlay" width="39" height="39" viewBox="0 0 39 39" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="39" height="39" rx="19.5" fill="white"/>
                            <path
                                d="M16.4849 12.1041C16.6047 12.0347 16.7388 12 16.8731 12C17.007 12 17.141 12.0347 17.2613 12.1041L26.5151 18.8278C26.7549 18.9663 26.9034 19.2228 26.9034 19.5001C26.9034 19.7774 26.7553 20.0338 26.5151 20.1722L17.2613 26.8962C17.0212 27.0346 16.7251 27.0346 16.4851 26.8962C16.2449 26.7574 16.0967 26.5008 16.0967 26.2237V12.7763C16.0967 12.4992 16.2447 12.2427 16.4849 12.1041Z"
                                fill="#F55555"/>
                        </svg>
                        <img src={`https://i.ytimg.com/vi_webp/${currentContent.videoHref}/maxresdefault.webp`} alt='' />
                    </a>
                </div>
                <ModalVideo
                    key={currentContent.videoHref}
                    channel="youtube"
                    autoplay
                    isOpen={uiStore.trainModal[currentContent.videoHref]}
                    videoId={currentContent.videoHref}
                    onClose={() => {
                        uiStore.trainModal[currentContent.videoHref] = false;
                    }}
                />
            </>}
        </div>
    );
});