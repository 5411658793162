import s from "../Support.module.scss";
import { LinkChat } from "./LinkChat";
import { images } from "../../../components/Utils/componentUtils";
import { TN_BOT } from "../../../data";
import React from "react";
import { useStore } from "../../../stores/useStore";

export function LinkChatBlock() {
    const { userStore } = useStore();
    return (
        <div className={s.linkChatBlock}>
            <LinkChat
                title={"Вопросы и Болталка"}
                info={"Чат с модератором проекта для срочных вопросов"}
                imageSrc={images["chat1.png"].default}
                chatLink={userStore.chats.question}
            />
            <LinkChat
                title={"Техническая поддержка"}
                info={"Чат с технической поддержкой и личным куратором"}
                imageSrc={images["chat2.png"].default}
                chatLink={TN_BOT}
            />
        </div>
    );
}